import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from '../Pages/Home';
import DiscoverPeruvanam from '../Pages/DiscoverPeruvanam';
import History from '../Pages/DiscoverPeruvanam/History';
import PeruvanamPooram from '../Pages/DiscoverPeruvanam/PeruvanamPooram';
import EventCalender from '../Pages/DiscoverPeruvanam/EventCalender';
import Arts from '../Pages/DiscoverPeruvanam/Arts';
import Panchavadyam from '../Pages/DiscoverPeruvanam/Panchavadyam';
import KoothuKoodiyattam from '../Pages/DiscoverPeruvanam/KoothuKoodiyattam';
import Melam from '../Pages/DiscoverPeruvanam/Melam';
import Nettipattam from '../Pages/DiscoverPeruvanam/Nettipattam';
import OtherArtsCrafts from '../Pages/DiscoverPeruvanam/OtherArtsCrafts';
import Architecture from '../Pages/DiscoverPeruvanam/Architecture';
import Mana from '../Pages/DiscoverPeruvanam/Mana';
import Temples from '../Pages/DiscoverPeruvanam/Temples';
import PeruvanamKizhakkeNadavazhi from '../Pages/DiscoverPeruvanam/PeruvanamKizhakkeNadavazhi';
import IntractiveMap from '../Pages/DiscoverPeruvanam/IntractiveMap';
import TakeVirtualTour from '../Pages/DiscoverPeruvanam/VirtualTour';

import VisitPlan from '../Pages/PlanVisit'
import ThingsToDo from '../Pages/PlanVisit/ThingsToDo';
import Attractions from '../Pages/PlanVisit/Attractions';
import AttractionDetails from '../Pages/PlanVisit/AttractionsDetails';
import ActivityDetails from '../Pages/PlanVisit/ActivityDetails';
import ActivityAttractions from '../Pages/PlanVisit/ActivityAttractions';

import Food from '../Pages/PlanVisit/Food';
import DeliciousFood from '../Pages/PlanVisit/Food/DeliciousFood';
import GettingToPeruvanam from '../Pages/PlanVisit/GettingToPeruvanam'
import UsefullDownload from '../Pages/PlanVisit/UsefullDownload';
import EventGuide from '../Pages/PlanVisit/EventGuide';


import Tnc from '../Pages/Tnc';
import Policy from '../Pages/Policy'



const NavPath = () => {
    return(
        <>
        
        <BrowserRouter>
        {/* <Header /> */}
        <Routes>
            <Route  path='/' element={<Home/>}></Route>
            <Route  path='/discover-peruvanam' element={<DiscoverPeruvanam/>}></Route>
            <Route  path='/history' element={<History/>}></Route>
            <Route  path='/peruvanam-pooram' element={<PeruvanamPooram />}></Route>
            <Route  path='/event-calender' element={<EventCalender />}></Route>
            <Route  path='/arts' element={<Arts />}></Route>
            <Route  path='/Panchavadyam' element={<Panchavadyam />}></Route>
            <Route  path='/koothu-koodiyattam' element={<KoothuKoodiyattam />}></Route>
            <Route  path='/melam' element={<Melam />}></Route>
            <Route  path='/nettipattam' element={<Nettipattam />}></Route>
            <Route  path='/other-arts-crafts' element={<OtherArtsCrafts />}></Route>
            <Route  path='/architecture' element={<Architecture />}></Route>
            <Route  path='/mana' element={<Mana />}></Route>
            <Route  path='/temples' element={<Temples />}></Route>
            <Route  path='/peruvanam-kizhakke-nadavazhi' element={<PeruvanamKizhakkeNadavazhi />}></Route>
            <Route  path='/interactive-map' element={<IntractiveMap />}></Route>
            <Route  path='/virtual-tour' element={<TakeVirtualTour />}></Route>
            
            
            <Route  path='/plan-a-visit' element={<VisitPlan />}></Route>
            <Route  path='/things-to-do' element={<ThingsToDo />}></Route>
            <Route  path='/attractions' element={<Attractions />}></Route>

            <Route  path='/attractions-details/:id' element={<AttractionDetails />}></Route>
            <Route  path='/activity-details/:id' element={<ActivityDetails />}></Route>

            <Route  path='/activity-attractions/:id' element={<ActivityAttractions />}></Route>

            <Route  path='/food' element={<Food />}></Route>
            <Route  path='/food/delicious/:id' element={<DeliciousFood />}></Route>
            <Route  path='/getting-to-peruvanam' element={<GettingToPeruvanam />}></Route>
            <Route  path='/download' element={<UsefullDownload />}></Route>
            <Route  path='/event-guide' element={<EventGuide />}></Route>

            <Route  path='/tnc' element={<Tnc />}></Route>
            <Route  path='/policy' element={<Policy />}></Route>
        </Routes>
        </BrowserRouter>
        </>
        
    )
}

export default NavPath;