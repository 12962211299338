import React, {useEffect, useState} from 'react'
import { useSearchParams } from "react-router-dom";
//import GoogleMap from '../../Components/GoogleMap';

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
//import SideBarMap from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import InterativeSideBar from "../../Components/InterativeSideBar";
import dataTemple from '../../dataJson/interativeTemple.json';

const Panchavadyam = () => {
   const [params] = useSearchParams();
   const activeIndex = params.get("templeId") || 1;
   const imgObj = dataTemple.find(item => item.id == activeIndex);
  return (
    <div className="wrapper right-bar d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
       <InterativeSideBar activeIndex={activeIndex}/>
      </div>
      <InterativeSideBar activeIndex={activeIndex}/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader passClass="mb-2" hideAction={true} title="Interactive Map" breadCrumb={[]} previouPage="/discover-peruvanam"/>
        <div className="text-white"> 
        {/* {activeIndex && <GoogleMap activeIndex={activeIndex}/>}      */}
        <div id="intMap">
          <img src={`/assets/images/IntMap/${imgObj.name}.png`} className='img-fluid' lowsrc="/assets/images/loader.gif"/>
        </div>
        </div> 
      </div>
     <MoveTop/>
    </div>
  );
};

export default Panchavadyam;