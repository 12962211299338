const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.7425 7.83498L4.61833 8.95915C4.08 8.4129 3.5575 7.70831 3.20125 6.63165L4.73708 6.24373C4.99042 6.94831 5.34667 7.43123 5.7425 7.83498ZM6.70833 3.74998L3.54167 0.583313L0.375 3.74998H2.76583C2.78167 4.39123 2.82917 4.96915 2.91625 5.4679L4.45208 5.07998C4.39667 4.69998 4.35708 4.24873 4.34917 3.74998H6.70833ZM14.625 3.74998L11.4583 0.583313L8.29167 3.74998H10.6588C10.5796 6.66331 9.64542 7.5104 8.64792 8.40498C8.25208 8.75331 7.84833 9.13331 7.5 9.63206C7.23083 9.24415 6.92208 8.9354 6.60542 8.6504L5.48917 9.76665C6.22542 10.4396 6.70833 10.9858 6.70833 12.4583V16.4166H8.29167V12.4583C8.29167 10.8591 8.85375 10.3525 9.70875 9.58456C10.8013 8.6029 12.1471 7.38373 12.2421 3.74998H14.625Z" fill={fill}/>
</svg>
    </>
  );
};

export default Icon;
