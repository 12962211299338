import {Link} from 'react-router-dom' 
const ContentBox = () => {
  return (
    <div className="row text-white py-md-4 justify-content-center">
        <div className="col-xl-4 col-sm-6">
        <Link to="/melam" className="shape-box">                    
            <img src="/assets/images/shapes/melam.png" alt="Melam" className="img-fluid"/>
            <div className="shape-box-text">Melam</div>
        </Link>                    
    </div>
    <div className="col-xl-4 col-sm-6">
        <Link to="/panchavadyam" className="shape-box">                    
            <img src="/assets/images/shapes/panchavadyam.png" alt="Panchavadyam" className="img-fluid"/>
            <div className="shape-box-text">Panchavadyam</div>
        </Link>                    
    </div>
    
    <div className="col-xl-4 col-sm-6">
        <Link to="/nettipattam" className="shape-box">                    
            <img src="/assets/images/shapes/nettipattam.png" alt="Nettipattam" className="img-fluid"/>
            <div className="shape-box-text">Nettipattam</div>
        </Link>                    
    </div>

    <div className="col-xl-4 col-sm-6">
        <Link to="/koothu-koodiyattam" className="shape-box">
            <img src="/assets/images/shapes/koothu-koodiyattam-icon.png" alt="Koothu Koodiyattam" className="img-fluid"/>      
            <div className="shape-box-text">Koothu Koodiyattam</div>
        </Link>
    </div>
    
    
    <div className="col-xl-4 col-sm-6">
        <Link to="/other-arts-crafts" className="shape-box">                        
            <img src="/assets/images/shapes/other-arts-crafts.png" alt="Arts Crafts" className="img-fluid"/>
            <div className="shape-box-text">Other Arts and Crafts</div>
        </Link>                    
    </div>                
</div>
  );
};
export default ContentBox;
