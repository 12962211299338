const Icon = () => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1059_9718)">
          <path
            d="M21 7L19.0347 0H1.96525L0 7C0 7.8855 0.33075 8.69487 0.875 9.31262V18.375C0.875 19.8223 2.05275 21 3.5 21H10.5C11.9473 21 13.125 19.8223 13.125 18.375V10.0301C13.454 9.83938 13.7498 9.59612 14 9.31262C14.6414 10.0406 15.5802 10.5 16.625 10.5H17.5C17.8019 10.5 18.095 10.4615 18.375 10.3889V21H20.125V9.31262C20.6693 8.69575 21 7.8855 21 7ZM10.5 19.25H3.5C3.01787 19.25 2.625 18.8571 2.625 18.375V15.75H11.375V18.375C11.375 18.8571 10.9821 19.25 10.5 19.25ZM11.375 14H2.625V10.3889C2.905 10.4615 3.19812 10.5 3.5 10.5H4.375C5.41975 10.5 6.35775 10.0398 7 9.31262C7.64225 10.0406 8.58025 10.5 9.625 10.5H11.375V14ZM16.625 8.75C15.6599 8.75 14.875 7.96513 14.875 7H13.125C13.125 7.96513 12.3401 8.75 11.375 8.75H9.625C8.65987 8.75 7.875 7.96513 7.875 7H6.125C6.125 7.96513 5.34013 8.75 4.375 8.75H3.5C2.57162 8.75 1.81037 8.02375 1.7535 7.11025L3.28475 1.75H6.125V4.375H7.875V1.75H13.125V4.375H14.875V1.75H17.7153L19.2465 7.11025C19.1896 8.02375 18.4275 8.75 17.5 8.75H16.625Z"
            fill="#F2F5FC"
          />
        </g>
        <defs>
          <clipPath id="clip0_1059_9718">
            <rect width="21" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Icon;
