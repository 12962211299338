import {Link} from 'react-router-dom'
import HeaderAction from "./HeaderAction";

const InnerHeader = ({ title, breadCrumb, previouPage, passClass, hideAction }) => {
  const breadCrumbLink = () =>{return breadCrumb.map((item, key) => {
    const activeClass = item.pageLink ? 'breadcrumb-item': 'breadcrumb-item active';
    const link = item.pageLink ? <Link to={item.pageLink}>{item.title}</Link> : item.title
    return (
    <li className={activeClass} key={key}>
    {link}
  </li>
  )})}

  return (
    <div className={`row align-items-center ${passClass ? 'mb-2' : 'mb-4'}`}>
      <div className="col-xxl-7 col-xl-6">
        <div className="d-flex align-items-center">
          <Link to={previouPage} className="pe-md-4 pe-3 py-4">
            <span className="icon">
              <img src="/assets/images/big-arrow.svg" className="w-768-26" />
            </span>
          </Link>
          <div className="ps-3 pe-3 text-white border-primary-white w-100">
            <h3>{title} </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                {breadCrumbLink()}
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {!hideAction && <HeaderAction />}
      
    </div>
  );
};

export default InnerHeader;
