const Icon = () => {
  return (
    <>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1737 5.72758C15.7232 3.00591 13.3466 0.916748 10.5003 0.916748C8.41116 0.916748 6.50408 2.04758 5.49783 3.84925C2.91991 4.18466 0.916992 6.41758 0.916992 9.06258C0.916992 11.9663 3.28408 14.3334 6.18783 14.3334H15.7712C18.1478 14.3334 20.0837 12.3976 20.0837 10.0209C20.0837 7.77842 18.3682 5.92883 16.1737 5.72758ZM15.7712 12.4167H6.18783C4.33824 12.4167 2.83366 10.9122 2.83366 9.06258C2.83366 7.39508 4.08908 5.96716 5.74699 5.74675L6.69574 5.62216L7.16533 4.78841C7.84574 3.58091 9.12033 2.83341 10.5003 2.83341C12.3595 2.83341 13.9791 4.21341 14.2857 6.04383L14.5253 7.5005L16.0012 7.63467C17.2182 7.74967 18.167 8.79425 18.167 10.0209C18.167 11.3434 17.0937 12.4167 15.7712 12.4167Z"
          fill="#F2F5FC"
        />
        <path
          d="M13.1839 15.2917L10.4048 18.4734L12.3214 19.4317L10.0693 22.0001H12.6089L15.3881 18.8184L13.4714 17.8601L15.7235 15.2917H13.1839Z"
          fill="#F2F5FC"
        />
        <path
          d="M7.43392 15.2917L4.65475 18.4734L6.57142 19.4317L4.31934 22.0001H6.85892L9.63809 18.8184L7.72142 17.8601L9.9735 15.2917H7.43392Z"
          fill="#F2F5FC"
        />
      </svg>
    </>
  );
};

export default Icon;
