import { Link } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from 'better-react-carousel';
import { assetesUrl } from '../Constant';

const Attraction = ({attractionData, activityData}) => {
    return(
        <div className="wrapper-container">
            
        <div className="w-100">
            <div className="d-flex justify-content-between">
                <h4 className="h4-sm mb-3 text-grey">Attractions</h4>
                <Link to="/attractions" className="font-14 text-primary">See More</Link>
            </div>

            <div className="related-attractions-box">
            <Carousel cols={4} rows={1} gap={10} loop={true}>
                {attractionData.map((item, key)=>
                 <Carousel.Item key={key}>
                         <div className="" >
                         <Link to={`/attractions-details/${item.id}`}  className="banner small sm overlay-dark" style={{backgroundImage: `url('${assetesUrl}/${item.banner}.svg')`}}>
                             <div className="banner-box d-flex align-items-center justify-content-between w-100">
                                 <h5 className="heading">{item.name}</h5>
                                 <div className="circle-30 d-flex align-items-center justify-content-center ms-auto">
                                     <img src="/assets/images/icons/arrow-right.svg" className="w-6" alt="Next"/>
                                 </div>
                             </div>
                         </Link>                            
                     </div>
                     </Carousel.Item>
                  )}               
                </Carousel>
                <div className="shadow-box d-none"></div>
            </div>

            <h4 className="h4-sm mb-3 text-grey">Activities</h4>
            <div className="row text-decor-none">
            
            {activityData.map((item, key)=>
                <div className="col-xl-3 col-lg-4 col-sm-6" key={key}>
                    <div className="card-dark">
                        <Link to={`/activity-attractions/${item.id}?activityName=${item.name}`}>
                            <div className="d-flex">
                                <div className="">                    
                                    <img src={`${assetesUrl}/${item.icon}.svg`} className="" alt={item.name}/>
                                </div>
                                <div className="circle-30 d-flex align-items-center justify-content-center ms-auto">                                            
                                    <img src="/assets/images/icons/arrow-right.svg" className="" alt="Next"/>
                                </div>
                            </div>
                            <h5 className="card-heading">{item.name}</h5>
                        </Link>
                    </div>
                </div>
                 )}  
            </div>                                        
        </div>                                    
    </div>
    )
} 
export default  Attraction; 