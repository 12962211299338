const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.47044 14.4161C7.80709 14.4298 8.15088 14.4369 8.5 14.4369C12.7631 14.4369 16.2188 13.3735 16.2188 12.0619C16.2188 11.3013 15.0574 10.6244 13.2512 10.1898M1.37559 11.1469C0.992625 11.4284 0.78125 11.7377 0.78125 12.0619C0.78125 12.9781 2.4675 13.7731 4.9375 14.1691M10.2812 3.15625H3.15625V10.2812H10.2812V3.15625ZM10.2812 3.15625L13.25 0.78125" stroke={fill} strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.2812 10.2812L13.25 7.90625V0.78125H6.125L3.15625 3.15625M9.09375 12.6562L7.3125 14.4375L9.09375 16.2188" stroke="#F2F5FC" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  );
};

export default Icon;
