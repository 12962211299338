export const monthNameArr = [
    {
        id: 1, 
        shortLabel: 'Jan',
        text: 'January'
    },
    {
        id: 2, 
        shortLabel: 'Feb',
        text: 'February'
    },
    {
        id: 3, 
        shortLabel: 'Mar',
        text: 'March'
    },
    {
        id: 4, 
        shortLabel: 'Apr',
        text: 'April'
    },
    {
        id: 5, 
        shortLabel: 'May',
        text: 'May'
    },
    {
        id: 6, 
        shortLabel: 'Jun',
        text: 'June'
    },
    {
        id: 7, 
        shortLabel: 'Jul',
        text: 'July'
    },
    {
        id: 8, 
        shortLabel: 'Aug',
        text: 'August'
    },
    {
        id: 9, 
        shortLabel: 'Sept',
        text: 'September'
    },
    {
        id: 10, 
        shortLabel: 'Oct',
        text: 'October'
    },
    {
        id: 11, 
        shortLabel: 'Nov',
        text: 'November'
    },
    {
        id: 12, 
        shortLabel: 'Dec',
        text: 'December'
    }
]

export const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };