import InnerHeader from "../../../Components/Common/InnerHeader";
import HeaderMobile from "../../../Components/Common/HeaderMobile";
import SideBar from "../../../Components/Common/SideBar";
import ContentBox from "../Content/event-calender";
import MoveTop from "../../../Components/Common/MoveTop";
import NextPreviousPage from "../../../Components/Common/NextPreviousPage"
import Footer from "../../../Components/Common/Footer";
import Events from './Events'

const EventCalender = () => {
  const breadCrumb = [
    {
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Peruvanam Pooram',
    pageLink: '/peruvanam-pooram'
  },
  {
    title: 'Pooram Event Calendar',
    pageLink: ''
  }
];

  return (
    
    <div className="wrapper d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="2"/>
      </div>
      <SideBar activeIndex="2"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Peruvanam Pooram" breadCrumb={breadCrumb} previouPage="/peruvanam-pooram"/>
        {/* <ContentBox /> */}
        <div className="pt-md-2 pt-0 text-white">
        <Events />
        </div>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default EventCalender;
