const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.8333 8.70829V10.2916H13.2499L10.8749 2.37496V0.791626H9.29158V2.37496H7.6845V0.791626H6.10117V2.46996L3.74992 10.2916H2.16659V8.70829H0.583252V17.4166H7.70825V13.4583H9.29158V17.4166H16.4166V8.70829H14.8333ZM11.1203 8.70829H5.8795L6.3545 7.12496H10.6453L11.1203 8.70829ZM10.1703 5.54163H6.8295L7.3045 3.95829H9.69533L10.1703 5.54163ZM14.8333 15.8333H10.8749V11.875H6.12492V15.8333H2.16659V11.875H4.9295L5.4045 10.2916H11.5953L12.0703 11.875H14.8333V15.8333Z" fill={fill}/>
</svg>
    </>
  );
};

export default Icon;
