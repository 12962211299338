import StaticPageCrousel from "../../../Components/StaticPageCrousel";
const ContentBox = ({ data, heading }) => {
  return (
    <div className="pt-md-2 pt-0 text-white">
      <div>
        <p className="lg">
          The temples of the erstwhile Peruvanam province are exemplars of
          Kerala religious architecture in Kerala, laid out with a series of
          enclosures like the chuttambalam and naalambalam, consisting of
          geometric forms and accompanied by a kulam or a temple pond nearby.
          Twenty four Sastha and Bhagavathy shrines participate in the
          Peruvanam-Arattupuzha Pooram festival and many of them, such as the
          Arattupuzha and Thriprayar temples trace their origins back to ancient
          times. The Pooram temples are as follows:
        </p>
        <ul>
          <li>
            Peruvanam Mahadeva Temple - Host temple of the Peruvanam Pooram
          </li>
          <li>
            Aarattupuzha Sree Sastha Temple - Host temple of the Aarattupuzha
            Pooram
          </li>
          <li>Anthikad Bhagavathy Temple</li>
          <li>Aykunnu Bhagavathy Temple</li>
          <li>Chakkamkulangara Sastha Temple</li>
          <li>Chalakudy Pisharikkal Bhagavathy Temple</li>
          <li>Chathakudam Sree Dharma Sastha Temple</li>
          <li>Cherpu Bhagavathy Temple</li>
          <li>Chittichathakudam Sastha Temple</li>
          <li>Choorakode Sree Durga Bhagavathi Temple</li>
        </ul>
      </div>
      <div></div>
      <StaticPageCrousel data={data} heading={heading} />
    </div>
  );
};

export default ContentBox;
