import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import Loader from "../../Components/Common/Loader";
import { apiBaseURL, assetesUrl } from "../../Constant";
import RelatedPost from "../../Components/RelatedPost";

import GoogleMap from "../../Components/GoogleMap";

const AttractionDetails = (props) => {
  const [attraction, setAttraction] = useState({});
  const [dataRelatedPosts, setDataRelatedPosts] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [params] = useSearchParams();

  const showActivity = params.get("showActivity");
  const activityId = params.get("activityId");

  const routeParams = useParams();
  const { id } = routeParams;

  // const breacrumActivity = Object.keys(attraction).length !== 0  ? attraction.activities[0].attractionActivities_id.name : '';
  const attractionList = () => {
    setIsLoading(true);
    // {{host}}/items/attractionListing/de125fe3-810b-4c42-86d9-c0f70d7c97e7?fields=,activities.,activities.attractionActivities_id.*&sort=date_created&to_show=mobile
    axios
      .get(
        `${apiBaseURL}/items/attractionListing?fields=*,activities.*,activities.attractionActivities_id.*&sort=date_created`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setApiData(data);
        // const productsDetails = data.find((itm) => itm.id === id);
        setAttraction(data.find((itm) => itm.id === id));

        setDataRelatedPosts(data.filter((itm) => itm.id !== id));

        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    attractionList();
  }, []);

  useEffect(() => {
    if (apiData.length) {
      const productsDetails = apiData.find((itm) => itm.id === id);
      setDataRelatedPosts(apiData.filter((itm) => itm.id !== id));
      setAttraction(productsDetails);
    }
  }, [id, apiData]);

  const breadCrumbDetails = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Things to Do",
      pageLink: "/things-to-do",
    },
    {
      title: "Attractions",
      pageLink: "",
    },
  ];
  const breadCrumbDetailsActivity = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Things to Do",
      pageLink: "/things-to-do",
    },
    {
      title: "demo", // breacrumActivity,
      pageLink: `/activity-attractions/${activityId}`,
    },
    {
      title: "Activity Details",
      pageLink: "",
    },
  ];

  const attarTempleData = {
    lat:  attraction.latitude && Number(attraction.latitude),
    lng: attraction.longitude && Number(attraction.longitude),
    templeName: attraction.name && attraction.name,
  };
  const mapUrl = attraction.latitude && attraction.longitude
  ? `http://www.google.com/maps/place/${attraction.latitude},${attraction.longitude}`
  : "";
  const breadCrumb = showActivity
    ? breadCrumbDetailsActivity
    : breadCrumbDetails;

  const detailsHeading = "Attractions Details";

  const headingRelatedText = "See Related Attractions";
  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="11" />
      </div>
      <SideBar activeIndex="11" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title={detailsHeading}
          breadCrumb={breadCrumb}
          previouPage="/things-to-do"
        />

        <div className="wrapper-container">
          <div className="w-100">
            <div className="row">
              <div className="col-lg-8">
                <div className="border-bottom border-light-06 pb-3">
                  <div>
                    <img
                      src={`${assetesUrl}/${attraction.banner}.svg`}
                      className="rounded-14 object-fit-cover w-100 h-250"
                      alt="Vaidyaratnam Ayurveda Museum"
                    />
                  </div>
                  <div className="p-2">
                    <h3 className="text-white h4-sm mb-1 text-truncate">
                      {attraction.name}
                    </h3>
                    {/* <div className="d-flex align-items-center">
                      <svg
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0.499634C2.85 0.499634 0 2.91463 0 6.64963C0 9.03463 1.8375 11.8396 5.505 15.0721C5.79 15.3196 6.2175 15.3196 6.5025 15.0721C10.1625 11.8396 12 9.03463 12 6.64963C12 2.91463 9.15 0.499634 6 0.499634ZM6 7.99963C5.175 7.99963 4.5 7.32463 4.5 6.49963C4.5 5.67463 5.175 4.99963 6 4.99963C6.825 4.99963 7.5 5.67463 7.5 6.49963C7.5 7.32463 6.825 7.99963 6 7.99963Z"
                          fill="#A5A7C2"
                        />
                      </svg>
                      <p className="mb-0 text-grey ps-2 text-truncate">
                        {attraction.location}
                      </p>
                    </div> */}
                  </div>
                </div>

                <div className="border-bottom border-light-06 py-3">
                  <p className="mb-2 text-grey">Highlights</p>
                  <div className="card-dark border-lt text-white px-3 pb-0 pt-3 mb-0">
                    <ul className="list-none font-14 p-0">
                      {attraction.highlights &&
                        attraction.highlights.map((itm, key) => (
                          <li className="mb-2" key={key}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.1935 2.92668L6.52681 9.59335C6.26681 9.85335 5.84681 9.85335 5.58681 9.59335L3.70015 7.70668C3.44015 7.44668 3.44015 7.02668 3.70015 6.76668C3.96015 6.50668 4.38015 6.50668 4.64015 6.76668L6.05348 8.18001L12.2468 1.98668C12.5068 1.72668 12.9268 1.72668 13.1868 1.98668C13.4535 2.24668 13.4535 2.66668 13.1935 2.92668ZM9.51348 0.82668C8.38682 0.36668 7.10681 0.20668 5.77348 0.44668C3.06015 0.933347 0.893481 3.12001 0.433481 5.83335C-0.326519 10.3333 3.42015 14.1867 7.89348 13.6067C10.5335 13.2667 12.7468 11.3 13.4401 8.73335C13.7068 7.75335 13.7335 6.80668 13.5801 5.92001C13.4935 5.38668 12.8335 5.18001 12.4468 5.56001C12.2935 5.71335 12.2268 5.94001 12.2668 6.15335C12.4135 7.04001 12.3468 7.98668 11.9201 8.99335C11.1468 10.8 9.46681 12.1267 7.51348 12.3067C4.11348 12.62 1.29348 9.74001 1.71348 6.32001C2.00015 3.96001 3.90015 2.04001 6.25348 1.71335C7.40681 1.55335 8.50015 1.77335 9.43348 2.25335C9.69348 2.38668 10.0068 2.34001 10.2135 2.13335C10.5335 1.81335 10.4535 1.27335 10.0535 1.06668C9.87348 0.98668 9.69348 0.900013 9.51348 0.82668Z"
                                fill="#E8BD70"
                              />
                            </svg>
                            <span className="ps-1">{itm.highlights}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="border-bottom border-light-06 py-3">
                  <p className="mb-2 text-grey">Attraction Details </p>
                  <div className="card-dark border-lt text-white px-3 py-3 mb-0">
                    {attraction.attractionDetails}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="border-bottom border-light-06 pt-lg-0 pt-3">
                  <div className="d-flex justify-content-between">
                    <p className="mb-3 text-grey">Map</p>

                    {mapUrl && (
                      <div>
                        <a
                          href={mapUrl}
                          className="text-primary font-12 pe-1"
                          target="_blank"
                        >
                          View on Google Map
                        </a>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.6667 0L11.56 0.02L8 1.4L4 0L0.24 1.26667C0.0999999 1.31333 0 1.43333 0 1.58667V11.6667C0 11.8533 0.146667 12 0.333333 12L0.44 11.98L4 10.6L8 12L11.76 10.7333C11.9 10.6867 12 10.5667 12 10.4133V0.333333C12 0.146667 11.8533 0 11.6667 0ZM4.66667 1.64667L7.33333 2.58V10.3533L4.66667 9.42V1.64667ZM1.33333 2.30667L3.33333 1.63333V9.43333L1.33333 10.2067V2.30667ZM10.6667 9.69333L8.66667 10.3667V2.57333L10.6667 1.8V9.69333Z"
                            fill="#E8BD70"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div className="text-white product-map">
                    {attraction?.name && attraction?.latitude && attraction?.longitude &&(
                      <GoogleMap attarTempleData={attarTempleData} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <RelatedPost
              data={dataRelatedPosts}
              heading={headingRelatedText}
              showActivity={showActivity}
              activityId={activityId}
            />
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default AttractionDetails;
