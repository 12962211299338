import HeaderAction from "./HeaderAction";
const Header = () => {
  return (
    <div className="row align-items-center mb-4">
      <div className="col-xxl-7 col-xl-6">
        <div className="d-flex align-items-center">
          <div className="pe-md-4 pe-3 py-4">
            <span className="icon">
              <img src="/assets/images/icons/discover.svg" className="w-768-26" />
            </span>
          </div>
          <div className="ps-3 pe-3 text-white border-primary-white w-100">
            <h3>
              Discover <span className="text-primary">Peruvanam</span>
            </h3>
            <p className="mb-0 main-sub">
              Everything you need to know about this hidden gem in the heart of
              Kerala, offering a unique blend of history, cultural heritage and
              idyllic landscapes.
            </p>
          </div>
        </div>
      </div>
      <HeaderAction />
    </div>
  );
};

export default Header;
