import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/arts";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage";
import Footer from "../../Components/Common/Footer";

const Arts = () => {
  const breadCrumb = [
    {
      title: "Discover Peruvanam",
      pageLink: "/discover-peruvanam",
    },
    {
      title: "Arts",
      pageLink: "",
    }
  ];

  const previousLink = {
    title: "Peruvanam Pooram",
    pageLink: "/peruvanam-pooram",
  };

  const nextLink = {
    title: "Architecture",
    pageLink: "/architecture",
  };
  return (
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="3"/>
      </div>
      <SideBar activeIndex="3"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Arts of the Peruvanam"
          breadCrumb={breadCrumb}
          previouPage="/discover-peruvanam"
        />
        <ContentBox />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink} />
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default Arts;
