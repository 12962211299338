import {Link} from 'react-router-dom' 
import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
    <div className="pt-md-2 pt-0 text-white">
      <h2 className="mb-xl-2">
        Peruvanam <span className="text-primary">Pooram</span>
      </h2>
      <Link
        to="/event-calender"
        className="btn btn-primary-07 px-5 text-white mb-4 align-items-center w-sm-100 event_Cal_btn_cstm"
        style={{ minWidth: `315px` }}
      >
        <span className="icon pe-2 pt-0">
          <img src="/assets/images/icons/calendar.svg" />
        </span>{" "}
        View Calendar
      </Link>

      <div>
        <p className="lg">
          The Peruvanam of the past was a land of myths and mysteries, many of
          which have left no remnants. However, the greatest of them all
          continues to exist, as a timeless testament to the province’s rich
          heritage and as what we know to be the oldest of all Pooram festivals
          in Kerala.
        </p>
    
      </div>
      <div>
        <ul className="mb-xl-3 mt-4">
          <li>Pooram – crowd.</li>
        </ul>
        <p className="lg">
          The Peruvanam-Aarattupuzha Pooram is a spectacle of congregation,
          music and spirituality, recurring every year in the Malayalam month of
          Meenam. The sequential occurrence of multiple events at venues all
          across the Thrissur district, an audience that transcends social
          hierarchies and enthralling percussion performances seen nowhere else
          make this festival a true phenomenon.
        </p>
       
      </div>
      <div>
        <p className="lg">
          The central deities of twenty four temples participate in the
          festivities, stretching over thirteen days and culminating on the
          Pooyam and Pooram days as the Peruvanam Pooram and the Aarattupuzha
          Pooram respectively. The festival begins with twenty three deities
          exiting their temples and traversing streets, neighbourhoods, bunds
          and fields to reach the venues of Peruvanam and Aarattupuzha. Each
          deity is accompanied with an entourage of people and elephants and the
          processional routes taken by them have been witnessing Pooram transit
          for hundreds of years.
        </p>
       
      </div>
      <div>
        <p className="lg">
          The Peruvanam Pooram celebration starts at 4 PM on Pooyam, the 8th day
          of the Pooram - with the Pisharikkal Bhagavathy deity beginning the
          Panchari Melam at the Nadavazhi. This is followed by the melam and
          elephant processions of Chathakudam Sastha, Thottippaal Bhagavathy,
          Aarattupuzha Sastha, Medamkulangara Sastha, Kalleli Sastha,
          Urakathamma Thirvadi, Cherpu Bhagavathy and Aykunnu Bhagavathy.
        </p>
   
      </div>
      <div>
        <p className="lg">
          On the eleventh day, in the Aarattupuzha Pooram, the Aarattupuzha
          Sastha, Edakunni Bhagavathy, Chathakudam Sastha, Thottippaal
          Bhagavathy, Anthikad Bhagavathy, Choorakode Bhagavathy, Kodakkara
          Sastha, Nettissery Sastha temples take turns in playing Panchari Melam
          outside the Aarattupuzha temple. The highlight of the Aarattupuzha
          Pooram is the Kootiezhunellippu. This comprises the assembly of 60-odd
          elephants with the Thriprayar Thevar, Urakathamma Thiruvadi and Cherpu
          Bhagavathy deities. The assembly is preceded by a Pandi Melam
          performance lasting three hours.
        </p>
  
      </div>
      <div>
        <p className="lg">
          An event of this scale and complexity entails extensive planning and
          coordinated execution of efforts. And so it does; this Pooram is the
          fruit of labour of the Pooram Central Committee- a team of 82 members
          with representatives from 23 Upadeshaka Samithis and one Barana
          Samithi.
        </p>
  
      </div>
      <div>
        <p className="lg">
          The Peruvanam-Aarattupuzha Pooram is exceptional in not one but a
          variety of aspects, making us wonder: how did this festival come to
          be?
        </p>
      
      </div>

      <h2 className="mb-xl-3 mt-5">
        History and <span className="text-primary">Evolution.</span>
      </h2>
      <div>
        <p className="lg">
          The Peruvanam Pooram of today bears some differences to that of the
          past; it was once a 28-day festival that saw participation of deities
          from 108 villages. The earliest references to the Peruvanam Pooram are
          from the 15th century. Theories state that the festival may have
          originated as a reflection of regional harmony and as a means of
          integrating people from all strata of society. Some also suggest that
          several smaller, ancient riverine festivals could have amalgamated to
          form the longer Pooram event.
        </p>
        
      </div>
      <div>
        <p className="lg">
          Another aspect worthy of examination is the participation of multiple
          Sasthas and Bhagavathys across the region. Many of these deities are
          alliances or ‘relatives’, while a few even have long-established
          rivalries! It is believed that the expansion of the Peruvanam province
          is the reason for these connections. As the temple corporation grew by
          purchasing and accruing land, the central deity of the Peruvanam
          Mahadeva temple forged alliances with deities of other temples by
          declaring them consorts or members of its extended family. There may
          have also been associations between the manas that governed each of
          these temples. The current participants in the Pooram may have been
          the result of such ties established in the past.
        </p>
       
      </div>
      <div>
        <p className="lg">
          The art forms associated with the festival are strikingly unique and
          the origins of many precede the Pooram itself. Arts and temple
          rituals, which were once strictly confined within temple walls, were
          brought out by the Pooram and placed in the larger context for the
          public to enjoy.
        </p>
        <StaticPageCrousel data={data} heading={heading}/>
      </div>
    </div>
  );
};
export default ContentBox;
