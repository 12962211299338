const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
      <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6249 2.16665H7.29158H6.49992H2.54159V0.583313H0.958252V2.16665V10.0833V16.4166H2.54159V10.0833H5.70825H6.49992H13.6249L12.0416 6.12498L13.6249 2.16665ZM10.5691 6.71081L11.2816 8.49998H6.49992H5.70825H2.54159V3.74998H6.49992H7.29158H11.2895L10.577 5.53915L10.3395 6.12498L10.5691 6.71081ZM8.08325 6.12498C8.08325 6.99581 7.37075 7.70831 6.49992 7.70831C5.62909 7.70831 4.91658 6.99581 4.91658 6.12498C4.91658 5.25415 5.62909 4.54165 6.49992 4.54165C7.37075 4.54165 8.08325 5.25415 8.08325 6.12498Z" fill={fill}/>
      </svg>
    </>
  );
};

export default Icon;
