import { Link } from "react-router-dom";
import Carousel from "better-react-carousel";
import { assetesUrl } from "../Constant";
const responsive = [
  {
    breakpoint: 400,
    cols: 1,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: true,
  },
  {
    breakpoint: 600,
    cols: 2,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: true,
  },
  {
    breakpoint: 700,
    cols: 3,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: true,
  },
  {
    breakpoint: 1000,
    cols: 5,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: true,
  },
];
const StaticPageCrousel = ({ data, heading }) => {
  return (
    <>
      {data.length && (
        <div className="row text-decor-none pt-4 static-crowsel">
          <p className="lg mb-3 text-white">{heading}</p>

          <Carousel
            cols={1}
            rows={1}
            gap={20}
            loop={true}
            className="owl-attraction owl-carousel text-white"
          >
            {data.map((item, key) => (
              <Carousel.Item key={key}>
                <div className="overflow-hidden">
                  <div className="banner justify-content-center bg-cover small lg overflow-hidden">
                    <img src={item.img} className="w-full object-fit-cover" />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default StaticPageCrousel;
