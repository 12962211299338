import { Link } from "react-router-dom";
const PlanContent =() => {
return (
    <div className="content-five pt-md-2 pt-0">
    <div className="content-block">
        <Link to="/things-to-do" className="banner overlay-dark" style={{backgroundImage: `url("assets/images/plan-a-visit/things-to-do-img.jpg")`}}>
            <div className="banner-box"> 
                <h5 className="text-white text-center"> Things to Do</h5>
            </div>
        </Link>
    </div>
    <div className="content-block">
        <Link to="/food" className="banner overlay-dark" style={{backgroundImage: `url('assets/images/plan-a-visit/food-img.jpg')`}}>
            <div className="banner-box">
                <h5 className="text-white text-center"> Peruvanam Cuisine</h5>
            </div>
        </Link>
    </div>
    <div className="content-block">
        <Link to="/getting-to-peruvanam" className="banner overlay-dark" style={{backgroundImage: `url('assets/images/plan-a-visit/getting-to-pervp-img.jpeg')`}}>
            <div className="banner-box">
                <h5 className="text-white text-center"> Getting To Peruvanam</h5>
            </div>
        </Link>
    </div>
    <div className="content-block">
        <Link to="/event-guide" className="banner overlay-dark" style={{backgroundImage: `url('assets/images/plan-a-visit/event-guide-img.png')`}}>
            <div className="banner-box">
                <h5 className="text-white text-center"> Event Guide</h5>
            </div>
        </Link>
    </div>
    <div className="content-block">
        <Link to="/download" className="banner overlay-dark" style={{backgroundImage: `url('assets/images/plan-a-visit/downloads-img.png')`}}>
            <div className="banner-box">
                <h5 className="text-white text-center"> Useful Downloads</h5>
            </div>
        </Link>
    </div>
</div>
)
}
export default PlanContent;