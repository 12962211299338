import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/mana";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const Mana = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Architecture',
    pageLink: '/architecture'
  },
  {
    title: 'Mana',
    pageLink: ''
  }
];

  const previousLink = {}

  const nextLink = {
    title: 'Temples',
    pageLink: '/temples'
  }
  let staticCrouselData = [];
  for(let i=1; i<=7 ; i++){
    staticCrouselData.push(
     { id:i,
      img: `/assets/images/img/DISCOVER-Architecture/Mana/mana-${i}.jpeg`,
    })
  }
  return (
    
    <div className="wrapper d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="4"/>
      </div>
      <SideBar activeIndex="4"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Mana" breadCrumb={breadCrumb} previouPage="/architecture"/>
        <ContentBox data={staticCrouselData} heading="" />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default Mana;
