const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.50008 2.35417L11.2501 5.91667V13.0417H1.75008V5.91667L6.50008 2.35417ZM6.50008 0.375L0.166748 5.125V14.625H12.8334V5.125L6.50008 0.375ZM6.10425 5.52083V7.89583H5.70841V5.52083H4.91675V7.89583H4.52091V5.52083H3.72925V7.89583C3.72925 8.55292 4.25966 9.08333 4.91675 9.08333V12.25H5.70841V9.08333C6.3655 9.08333 6.89591 8.55292 6.89591 7.89583V5.52083H6.10425ZM7.29175 7.10417V9.47917H8.08341V12.25H8.87508V5.52083C8.00425 5.52083 7.29175 6.23333 7.29175 7.10417Z" fill={fill}/>
    </svg>
 
    </>
  );
};

export default Icon;
