import {Link} from 'react-router-dom'
const NextPreviousPage = ({previousLink, nextLink}) => {
    return(
        <div className="pt-4 d-flex justify-content-between pb-5">
            {previousLink && previousLink.title && (
                <div>
                <Link to={previousLink.pageLink} className="d-flex align-items-center">
                    <button className="btn btn-transparent me-1 border p-2 rounded-12 rotate-180">
                        <img src="/assets/images/grey-arrow.svg" /></button>
                    <div className="text-grey ps-2 text-decoration-underline">{previousLink.title}</div>
                </Link>                    
            </div>
            )}
               {nextLink && nextLink.title && (
                <div className="ms-auto">
                <Link to={nextLink.pageLink} className="d-flex align-items-center">
                    <div className="text-grey pe-2 text-decoration-underline">{nextLink.title}</div>
                    <button className="btn btn-transparent me-1 border p-2 rounded-12">
                        <img src="/assets/images/grey-arrow.svg" /></button>                        
                </Link>                    
            </div>
               )} 
                
            </div>
    )
}

export default NextPreviousPage;