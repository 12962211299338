import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                            
                <h3 className="mb-xl-3">The home of the <span className="text-primary">Peruvanam Pooram</span> and the birthplace of the <span className="text-primary">Panchari Melam.</span></h3>
                <div>
                    <p className="lg">Located right outside the east gate of the Peruvanam Mahadeva Temple, the Nadavazhi (walkway) is the venue of the Peruvanam Pooram event that takes place on Pooyam day of the thirteen day-long festival. Adorned with galleries on either side of the walkway to seat the audience, the spatial dynamics of the Nadavazhi are specifically suited for the audience to enjoy a quality of the Panchari Melam that is unmatched and cannot be recreated elsewhere.
                    </p>
                             
                </div>   
                <StaticPageCrousel data={data} heading={heading}/>                
            </div>
  );
};

export default ContentBox;
