import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                
                
                <div>
                    <p className="lg">The koothu is a solo theatre performance which may be performed independently or as a part of a koodiyattam ensemble. A koothu performer, through the art, offers socio-political critique and commentary on current affairs of society. While the koothu is known for its narration, Koodiyattam which literally translates to ‘acting together’, emphasises expressiveness (abhinayam). Actors don elaborate makeup, costumes and props and are accompanied with the sound of a mizhavu in the background. There are several variants of these arts based on the gestures and the expressions employed. Both forms of ancient theater are traditionally performed between the 20th day of the month of Midhunam to the 1st of Chingam in a theatre space called the koothambalam and are not part of the Peruvanam  Pooram.
                   </p>
                                
                </div>
                <div>
                    <p className="lg">Koodiyattam has been honoured with a place in the Intangible Cultural Heritages list by UNESCO. It was originally performed privately within temples and its occurrence before a big audience is a recent phenomenon. Initially, each of the eighteen nadus of Kerala had one troupe or family that was conferred the right to perform Koodiyattam, many of which have become extinct or have migrated to other places from their hometowns. Restricting performing rights had its share of consequences, as illustrated in the story of Maani Chakkyar.
                    </p>
                                
                </div>

                <h2 className="mb-xl-3 mt-5">Story of <span className="text-primary">Maani Chakkyar:</span></h2>
                <div>
                    <ul>
                        <li className="mb-3 font-500">Back in the day, only two families from the Chakkyar clan in the Peruvanam region- Mekkaadu and Kuttancherry had reserved the rights to perform the adiyendhra koothu (koothu performed for temple rituals).</li>
                        <li className="mb-3 font-500">The tantric family of Padinjaredathu desired a third right of performance through a troupe of his own. He travels to Northern Malabar and brings along with him another Chakkyar named Maani to receive the right to perform on his behalf.</li>
                        <li className="mb-3 font-500">While this proposition was opposed by the two performer-families, the tantri had already given Maani the promise of a right. Thus, he took an oath before the Sabhayogam to not allow the performance of koothu without the establishment of a third share of rights for his own Chakkyar group.</li>
                        <li className="mb-3 font-500">However, the Sabhyogam went ahead with the performance of the Mekkaattu and Kuttancherry families in the koothambalam of the Peruvanam Mahadeva temple, following the intervention of the Moothadhu (protectors of temple performance protocols).</li>
                        <li className="mb-3 font-500">Enraged by this dismissal, the senior-most member of the Padinjaredathu family went on to destroy the sacred lamp of the koothambalam during the performance. It is said that this disruptive act angered the Irattaiyappan deity of the Peruvanam Mahadeva temple to such an extent that he killed the Padinjerathu Namboothiri along with nine members of his family with a snake.</li>
                        <li className="mb-3 font-500">The lone survivor of the family, a young boy, wished to avenge his family by stopping the performance. Acknowledging that killing this boy would wipe out the Padinjaredathu family in entirety, the deity agrees to giving Maani a third share of rights.</li>
                        <li className="mb-3 font-500">This negotiation was a complicated arrangement and was perceived as a defeat for the deity. An act of mahapataka was committed through the  murder of the tantri and his family.</li>
                        <li className="mb-3 font-500">The Mekkaattu and Kuttancherry families continue to exist in and around Peruvanam</li>                        
                    </ul>
                </div>
                <StaticPageCrousel data={data} heading={heading}/>
            </div>
  );
};

export default ContentBox;
