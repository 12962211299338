const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0417 4.93746C15.4789 4.93746 15.8333 4.58302 15.8333 4.14579C15.8333 3.70857 15.4789 3.35413 15.0417 3.35413C14.6044 3.35413 14.25 3.70857 14.25 4.14579C14.25 4.58302 14.6044 4.93746 15.0417 4.93746Z" fill={fill}/>
<path d="M11.8752 4.93746C12.3124 4.93746 12.6668 4.58302 12.6668 4.14579C12.6668 3.70857 12.3124 3.35413 11.8752 3.35413C11.4379 3.35413 11.0835 3.70857 11.0835 4.14579C11.0835 4.58302 11.4379 4.93746 11.8752 4.93746Z" fill={fill}/>
<path d="M13.4506 6.125C12.3581 6.125 11.4714 6.79 11.4714 7.61333H15.4298C15.4298 6.79 14.5431 6.125 13.4506 6.125Z" fill={fill}/>
<path d="M0.791748 11.6667C0.791748 14.2871 2.92133 16.4167 5.54175 16.4167C8.16216 16.4167 10.2917 14.2871 10.2917 11.6667V6.125H0.791748V11.6667ZM2.37508 7.70833H8.70841V11.6667C8.70841 13.4162 7.29133 14.8333 5.54175 14.8333C3.79216 14.8333 2.37508 13.4162 2.37508 11.6667V7.70833Z" fill={fill}/>
<path d="M8.7085 0.583313V4.93748H10.2918V2.16665H16.6252V6.12498C16.6252 7.87456 15.2081 9.29165 13.4585 9.29165C12.7064 9.29165 12.0256 9.01456 11.4793 8.57915V10.4396C12.081 10.7166 12.7539 10.875 13.4585 10.875C16.0789 10.875 18.2085 8.7454 18.2085 6.12498V0.583313H8.7085Z" fill={fill}/>
<path d="M3.95841 10.4791C4.39564 10.4791 4.75008 10.1247 4.75008 9.68748C4.75008 9.25025 4.39564 8.89581 3.95841 8.89581C3.52119 8.89581 3.16675 9.25025 3.16675 9.68748C3.16675 10.1247 3.52119 10.4791 3.95841 10.4791Z" fill={fill}/>
<path d="M7.12516 10.4791C7.56239 10.4791 7.91683 10.1247 7.91683 9.68748C7.91683 9.25025 7.56239 8.89581 7.12516 8.89581C6.68794 8.89581 6.3335 9.25025 6.3335 9.68748C6.3335 10.1247 6.68794 10.4791 7.12516 10.4791Z" fill={fill}/>
<path d="M5.54167 13.155C6.63417 13.155 7.52083 12.49 7.52083 11.6666H3.5625C3.5625 12.49 4.44917 13.155 5.54167 13.155Z" fill={fill}/>
</svg>
    </>
  );
};

export default Icon;
