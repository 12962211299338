const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.49992 3.15127L13.5587 11.25H3.44117L8.49992 3.15127ZM8.49992 0.166687L0.583252 12.8334H16.4166L8.49992 0.166687Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default Icon;
