import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="wrapper">
      <div className="row g-0">
        <div className="col-md-6">
          <div className="brand-logo">
          <Link to={`/`} className="logo text-center">
              <img
                src="assets/images/logo.png"
                className="img-fluid"
                alt="Peruvanam"
              />
            </Link>
          </div>
        </div>
        <div className="col-md-3 overflow-hidden">
        <Link to={`/discover-peruvanam`}
            className="banner overlay-dark"
            style={{backgroundImage: `url("assets/images/img/Discover-Peruvanam.jpg")`}}
          >
            <div className="banner-box">
              <div className="heading-box">
                <h4 className="heading-line">
                  {" "}
                  Discover Peruvanam
                  <span className="icon">
                    <img src="/assets/images/right-arrow.svg" />
                  </span>
                </h4>
              </div>
              <p className="para-text">
                Everything you need to know about this hidden gem in the heart
                of Kerala, offering a unique blend of history, cultural heritage
                and idyllic landscapes.
              </p>
            </div>
          </Link>
        </div>
        <div className="col-md-3 overflow-hidden">
          <Link
          to={`/plan-a-visit`}
            className="banner overlay-dark"
            style={{backgroundImage: `url("assets/images/img/Plan-a-Visit.jpg")`}}
          >
            <div className="banner-box">
              <div className="heading-box">
                <h4 className="heading-line">
                  {" "}
                  Plan a Visit
                  <span className="icon">
                    <img src="/assets/images/right-arrow.svg" />
                  </span>
                </h4>
              </div>
              <p className="para-text">
                Everything you need to plan a visit to experience the magic of
                Peruvanam. Whether you're interested in history, culture, or
                just want to relax by the river, Peruvanam has something to
                offer for everyone.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
