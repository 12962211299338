import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                                
                <div>
                    <p className="lg">Kerala’s manas, or illams, are ancestral homes of the namboothiri aristocracy which now exist as symbols of the families’ legacy.
                        They were also seats of provincial and temple administrative power, for the Uraalars were chosen from mana families. All of the twenty four
                        temples of the Peruvanam-Aarattupuzha Pooram were governed by one or more manas, while the manas were also able to be associated with many
                        temples. Many of the families that lived in these homes have given notable patronage to arts, crafts, science and vedic studies.
                    </p>
                             
                </div>
                <div>
                    <p className="lg"></p><p className="lg">Built in the traditional spatial arrangement of nalukettu, the architecture of manas were often reflective of the power and
                        prosperity of the families that lived in them- with multiple courtyards, thresholds, bathing ponds, granaries and one or more shrines of the
                        deities associated with the clan. The complexes were also able to accommodate processional elephants associated with their temples during
                        Pooram festivities and irakki pooja ceremonie.
                    </p>                    
                   
                </div>    
                <StaticPageCrousel data={data} heading={heading}/>            
            </div>
  );
};

export default ContentBox;
