import NavPath from './Routes';
// import logo from './logo.svg';
// import './App.css';

function App() {
  document.addEventListener('contextmenu', event => event.preventDefault());
  return (
    <div className="App">
      <NavPath />
    </div>
  );
}

export default App;
