import React, { useEffect, useState } from 'react';
import GoogleMaps from '../../../Components/GoogleMap';
import './events.css';

import assets from "./assets";
let tmpActiveMonth = [];
let tmpActiveEventRight = [];
let tmpActiveEventLeft = [];
const { RendezvousEventImage, EventDescriptionIcon } = assets;
const rightEvents = ['Kodimaram Nattal', 'Aswathy Vela', 'Ousadha Nivedyam', 'Karthika Purappadu', 'Thathwakalasham', 'Rohini Aarattu', 'Makeeram Purappadu', 'Makeeram Aarattu', 'Kodikayattam', 'Thiruvathira Aarattu', 'Kootala Pooram', 'Kattur Pooram', 'Thiruvathira Vilakku', 'Thiruvathira Purappadu', 'Punartham Aarattu', 'Blahayil Aarattu', 'Manaykkal Pooram', 'Kodannur Pooram', 'Chakkamkulangara vilakku', 'Pooyam Aarattu', 'Pakal Pooram', 'Chalukuthu', 'Aayilyam aarattu', 'Pidikkaparambu Pooram', 'Makam Aarattu', 'Uthram Pattu', 'Kodikuthu Pooram', 'Desham Valathu', 'Atham Kodikutthu']
const rightEventsIndex = [['one', 'two'], 'three', 'four', 'four', 'four', 'five', 'six', 'six', 'six', 'seven', 'seven', 'seven', 'seven', 'seven', 'eight', 'eight', 'eight', 'eight', 'eight', 'nine', 'nine', 'nine', 'ten', 'ten', 'eleven', 'thirteen', 'thirteen', 'thirteen', 'fourteen']
function Events({ onMonthSelect, onMonthDeselect }) {

  const [activeEventLeft, setActiveEventLeft] = useState([]);
  const [activeEventRight, setActiveEventRight] = useState([]);
  const [activeMonth, setActiveMonth] = useState(['one']);

  useEffect(() => {
    tmpActiveMonth = activeMonth;
    tmpActiveEventRight = activeEventRight;
    tmpActiveEventLeft = activeEventLeft;
  }, [activeEventLeft, activeEventRight, activeMonth])

  const handleMonthToggle = (name, leftEvent, rightEvent, index) => {
    // console.log("name ===>", name, tmpActiveMonth, tmpActiveEventRight, tmpActiveEventLeft)
    if (activeMonth.indexOf(index) > -1) {
      tmpActiveMonth = [];
      // tmpActiveMonth.splice(activeMonth.indexOf(index), 1)
      setActiveMonth([...tmpActiveMonth]);
      onMonthDeselect && onMonthDeselect(name)
    } else {
      // setActiveMonth([...activeMonth, index]);
      setActiveMonth([index]);
      onMonthSelect && onMonthSelect(name)

    }
  }
  const renderMonthName = (name, leftEvent, rightEvent, index) => {

    return (
      <div onClick={() => {
        handleMonthToggle(name, leftEvent, rightEvent, index)
      }} className={`section-two-month-name ${activeMonth.indexOf(index) > -1 ? 'active-month' : ''}`}>
        <div className={`month-name-left-mark-dote  ${activeMonth.indexOf(index) > -1 ? 'active-month-mark' : ''}`} />
        <div className={`month-name-left-mark ${activeMonth.indexOf(index) > -1 ? 'active-month-mark' : ''}`} />
        {name}
        <div className={`month-name-right-mark  ${activeMonth.indexOf(index) > -1 ? 'active-month-mark' : ''}`} />
        <div className={`month-name-right-mark-dote  ${activeMonth.indexOf(index) > -1 ? 'active-month-mark' : ''}`} />
      </div>
    )
  }
  const renderSectionOneEvent = (name, description, descriptionIcon, index, monthIndex) => {
    return (
      <>
        <div className={`calender-section-one-events start-event-${index}`}>
          <div className={`calender-section-one-event-name ${activeMonth.indexOf(monthIndex) > -1 ? 'active-event' : ''}`}>{name}</div>
          <div className='calender-section-one-event-image'><RendezvousEventImage /></div>
          <div className={`calender-section-one-event-description calender-section-one-event-description-${index}`}>
            {description}  {descriptionIcon && <EventDescriptionIcon />}
          </div>
          <div className={`calender-section-one-event-mark calender-section-one-event-mark-${index} ${activeMonth.indexOf(monthIndex) > -1 ? 'active-event' : ''}`} />
        </div>
        <div className={`calender-section-one-link calender-section-one-link-${index} ${activeMonth.indexOf(monthIndex) > -1 ? 'active-event' : ''}`} />
      </>
    )
  }
  const renderRightSideLinking = (index, isShowPartThree, monthIndex) => {
    return (
      <div className={`section-three-list-item-link section-three-list-item-link-${index} ${activeMonth.indexOf(monthIndex) > -1 ? 'active-event' : ''}`}>
        <div className={`section-three-list-item-link-${index}-part-one`} />
        <div className={`section-three-list-item-link-${index}-part-two`} />
        {isShowPartThree && <div className={`section-three-list-item-link-${index}-part-three`} />}
      </div>
    )
  }
  return (
    <div className="root">
      <div className='calender-container-scroll'>
        <div className='calender-container'>
          <section className='calender-section-one'>
            <div className='calender-section-one-top-spacing' />
            <div className='calender-section-one-title'>
            Rendezvous Events
              <div className='calender-section-one-title-mark' />
            </div>
            {
              renderSectionOneEvent(
                'Rohini Vilakku',
                'Venue: Urakathamma Thiruvady temple ( 9 am ), Thottipal Bagavathy temple ( 8 am )',
                true, 'one', 'five')
            }
            {
              renderSectionOneEvent(
                'Tharakkal Pooram',
                'Venue - Maatil Sasthavu temple , Kaleli Sasthavu Temple ( 5 pm )',
                true, 'two', 'seven')
            }
            {
              renderSectionOneEvent(
                'Thiruvathira pakal pooram',
                'Venue - Kaleli Sasthavu temple ( 8 am to 1 am )',
                false, 'three', 'seven')
            }
            {
              renderSectionOneEvent(
                'Thaikattuseri Pooram',
                'Venue - Thaikattuseri Bagavathy temple ( 9 am to 1 am )',
                false, 'four', 'eight')
            }
            {
              renderSectionOneEvent(
                'Narukulangara Pooram',
                'Venue - Narukulangara Balarama temple ( 9 pm )',
                false, 'five', 'eight')
            }
            {
              renderSectionOneEvent(
                'Peruvanam Pooram',
                'Venue - Peruvanam Mahadeva temple ( 4 pm - 9 am )',
                false, 'six', 'nine')
            }
            {
              renderSectionOneEvent(
                'Aanaiottam',
                'Venue - Pidikkaparambu Padam ( 8 am )',
                false, 'seven', 'eleven')
            }
            {
              renderSectionOneEvent(
                'Tharakkal Pooram',
                'Venue - Aarattupuzha Sasthavu temple ( 6:30 pm )',
                false, 'eight', 'eleven')
            }
            {
              renderSectionOneEvent(
                'Aarattupuzha Pooram',
                'Venue - Aarattupuzha Sasthavu temple ( 10 pm - 9 am )',
                false, 'nine', 'twelve')
            }

            {
              renderSectionOneEvent(
                'Gramabali',
                'Venue - Aarattupuzha Sasthavu temple, Chathakudam Sasthavu temple, Thiruvilakavu Sasthavu temple Time ( 9 pm, 11 pm)',
                false, 'ten', 'thirteen')
            }

            {
              renderSectionOneEvent(
                'Uthram Vilakku',
                'Venue - Edakunny Bagavathy temple, Anthikaadu bagavthu temple, Choorakod Bagavathy temple, Aykunnu Bagavathy temple, Maatil Sasthu temple',
                false, 'eleven', 'thirteen')
            }

            {
              renderSectionOneEvent(
                'Idavazhi Pooram',
                'Venue - Edakunny Bagavathy temple ( 8 am )',
                false, 'twelve', 'fourteen')
            }
            {
              renderSectionOneEvent(
                'Pakal Pooram',
                'Venue - Peruvanam Mahadeva temple ( 12 pm )',
                false, 'thirteen', 'fourteen')
            }
            {
              renderSectionOneEvent(
                'Kodikkal Pooram',
                'Venue - Nettissery Sastha (12 am)',
                false, 'fourteen', 'fourteen')
            }
          </section>
          <section className='calender-section-two'>
            <div className='calender-section-two-borders' />
            <div className='section-two-title'>Days</div>
            <div className='section-two-month-title'>Kumbam</div>
            {renderMonthName('Pooram', [], ['one'], 'one')}
            {renderMonthName('Uthram', [], ['one'], 'two')}
            <div className='section-two-month-title'>Meenam</div>
            {renderMonthName('Aswathy', [], ['two'], 'three')}
            {renderMonthName('Karthika', [], ['three'], 'four')}
            {renderMonthName('Rohini', ['one'], ['four'], 'five')}
            {renderMonthName('Makeeram', [''], ['five'], 'six')}
            {renderMonthName('Thiruvathira', ['two'], ['six'], 'seven')}
            {renderMonthName('Punartham', ['three'], ['seven'], 'eight')}
            {renderMonthName('Pooyam', ['four'], ['eight'], 'nine')}
            {renderMonthName('Aahilyam', [], ['nine'], 'ten')}
            {renderMonthName('Makam', ['five'], ['ten'], 'eleven')}
            {renderMonthName('Pooram', ['six'], [], 'twelve')}
            {renderMonthName('Uthram', ['seven'], ['eleven'], 'thirteen')}
            {renderMonthName('Atham', ['eight'], ['twelve'], 'fourteen')}

          </section>
          <section className='calender-section-three'>
            <div className='section-three-title'>Events</div>
            <div className='section-three-list-container'>
              {
                rightEvents.map((e, i) => {
                  return (
                    <dvi key={i} className={`section-three-list-item  ${(i === 0 && (activeMonth.indexOf(rightEventsIndex[i][0]) > -1 || activeMonth.indexOf(rightEventsIndex[i][1]) > -1)) || activeMonth.indexOf(rightEventsIndex[i]) > -1 ? 'active-event-right' : ''}`}>
                      {e}
                    </dvi>
                  )
                })
              }
              {renderRightSideLinking('one', true, 'one')}
              {renderRightSideLinking('two', false, 'three')}
              {renderRightSideLinking('three', true, 'four')}
              {renderRightSideLinking('four', false, 'five')}
              {renderRightSideLinking('five', true, 'six')}
              {renderRightSideLinking('six', true, 'seven')}
              {renderRightSideLinking('seven', true, 'eight')}
              {renderRightSideLinking('eight', true, 'nine')}
              {renderRightSideLinking('nine', true, 'ten')}
              {renderRightSideLinking('ten', false, 'eleven')}
              {renderRightSideLinking('eleven', true, 'thirteen')}
              {renderRightSideLinking('twelve', false, 'fourteen')}
            </div>
          </section>
        </div>
        <div className='map-event'>
          <GoogleMaps eventIndex={activeMonth[0]} />
        </div>
        
      </div>
    </div>
  );
}
// 29
export default Events;
