import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">
                <div>
                    <p className="lg">While it is hard to label any single aspect of the Peruvanam Pooram as the most significant, the sustenance of its popularity through thousands of years can be largely attributed to the melam. Peruvanam, specifically, is hailed for its quality of melam training and performances, so much so that Pooram organisers all over Kerala wish to invite artists from the village to perform in their festivals.
                    </p>
                                 
                </div>
                <div>
                    <p className="lg">In the Pooram, the melam is usually played during the ezhunellippu or elephant processions and has engaged audiences of all ages and communities to such an extent that it is possible to discuss the nuances of the art with almost any spectator from Peruvanam. The most surprising fact about the performances is that they are extemporaneous and the kaalams to be played are decided by the pramani while the event is in progress.
                    </p>
                                     
                </div>

                <h2 className="mb-xl-3 mt-5">History and <span className="text-primary">Evolution.</span></h2>
                <div>
                    <p className="lg">Historically, the melam was bound within temple walls and performed solely in rituals and ceremonies for the deities. Its origin shares close associations with the five-thousand year history of the Peruvanam Mahadeva Temple. As the art began opening up to a wider audience with the inception of the Pooram, it took on a more energetic and entertaining avatar. To adapt the melam to its new arena, certain reconfigurations in the sound quality, called Kootipiditham, were adopted. Some of these changes were the tightening of the ropes on the chenda- a method referred to as moopu and an increase in the kaalam of the original composition. The art form was once solely performed by the Marars of Peruvanam who continue to practise it as their kulathozhil through generations. For the melam artists of the Marar community, the motivation to learn the art comes very early on in life, as they grow up watching their family members perform the art. Today, there are no bars on who can learn and perform the melam and many cultural institutions offer certificate courses in melam performance. Infact, today the art form even transcends its gender bias and young girls are in training to become melam artists.
                    </p>
                                   
                </div>

                <h2 className="mb-xl-3 mt-5">Variants</h2>
                <div>
                    <p className="lg">The melam orchestra makes use of the traditional Kerala instruments of chenda, ilathaalam, kombu and kuzhal to produce two compositions that are indispensable to the Peruvanam Pooram, called the Pandi and the Panchari.                        .
                    </p>
                    <ul>
                       <li className="mb-3 font-500"> Conceived by Manamangalam Narayanan Namboothiri and Pandarathil Raman Marar, the Panchari Melam was born within the confines of the Peruvanam Nadavazhi in honour of the Urakathamma Thiruvadi deity. It is performed in five stages and with a six-beat taalam, for over four hours.</li>
                       <li className="mb-3 font-500"> The Pandi Melam uses a seven-beat taalam and is performed in four stages for over 2 hours.</li>
                    </ul>                    
                </div>
                <StaticPageCrousel data={data} heading={heading}/>
            </div>
  );
};

export default ContentBox;
