import { Link } from "react-router-dom";
const HeaderMobile = () => {
    return (
        <div className="nav-bar d-md-none d-block p-3">
            <div className="header-block">
                <button className="btn btn-transparent p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo" style={{height: '60px'}}>
                    <span className="icon"><img src="/assets/images/icons/bars.svg" /></span>
                </button>
                <a href="/" className="logo text-center">
                    <img src="/assets/images/logo-sidebar.png" className="img-fluid" alt="Peruvanam" />
                </a>
            </div>
            <div className="d-flex">
                <button className="btn btn-primary me-3 w-50" data-bs-toggle="modal" data-bs-target="#exampleModal"><span className="icon pe-1"><img src="/assets/images/icons/watch-docuseries-white.svg"/></span> Watch Docuseries</button>
                <Link to="/interactive-map" className="btn btn-primary w-50"><span className="icon pe-1"><img src="/assets/images/icons/watch-docuseries-white.svg"/></span> View Interactive Map</Link>
            </div>
        </div>
    )
}
export default HeaderMobile;