import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/history";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";
import CommingSoon from "../../Components/ComingSoon";

const VirtualTour = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Take Virtual Tour',
    pageLink: ''
  }];

  const previousLink = {}
  const nextLink = {}


  return (
    
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="5"/>
      </div>
      <SideBar activeIndex="5"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Take Virtual Tour" breadCrumb={breadCrumb} previouPage="/discover-peruvanam"/>
        <div class="pt-md-2 pt-0 text-white">
        <CommingSoon />
        </div>
        
        {/* <ContentBox data={staticCrouselData} heading=""/> */}
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default VirtualTour;
