import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/koothu-koodiyattam";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const Panchavadyam = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Arts',
    pageLink: '/arts'
  },
  {
    title: 'Koothu Koodiyattam',
    pageLink: ''
  }
];

  const previousLink = {
    title: 'Nettipattam',
    pageLink: '/nettipattam'
  }

  const nextLink = {
    title: 'Other Arts & Crafts',
    pageLink: '/other-arts-crafts'
  }
  let staticCrouselData = [];

  const newImage = [
    {
      id: 3,
      img: '/assets/images/img/DISCOVER-Arts/Koothu-Koodiyattam/DISABLE RIGHT CLICK - Koothu Koodiyattam.png'
    }
  ];
  staticCrouselData = [...staticCrouselData, ...newImage];
  return (
    
    <div className="wrapper d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="3"/>
      </div>
      <SideBar activeIndex="3"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Koothu Koodiyattam" breadCrumb={breadCrumb} previouPage="/arts"/>
        <ContentBox data={staticCrouselData} heading=""  />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default Panchavadyam;
