import {Link} from 'react-router-dom' 
const ContentBox = () => {
  return (
    <div className="content-five pt-md-2 pt-0">
          <div className="content-block">
            <Link
              to="/history"
              className="banner overlay-dark"
              style={{ backgroundImage: `url('assets/images/img/Homepage/History.jpg')` }}
            >
              <div className="banner-box">
                <h5 className="text-white text-center">
                  {" "}
                  History of Peruvanam
                </h5>
              </div>
            </Link>
          </div>
          <div className="content-block">
            <a
              href="peruvanam-pooram"
              className="banner overlay-dark"
              style={{ backgroundImage: `url('assets/images/img/Homepage/Peruvanam-Pooram.jpg')` }}
            >
              <div className="banner-box">
                <h5 className="text-white text-center"> Peruvanam Pooram</h5>
              </div>
            </a>
          </div>
          <div className="content-block">
            <a
              href="arts"
              className="banner overlay-dark"
              style={{ backgroundImage: `url('assets/images/img/Homepage/Arts.jpg')` }}
            >
              <div className="banner-box">
                <h5 className="text-white text-center">
                  {" "}
                  Arts of the Peruvanam
                </h5>
              </div>
            </a>
          </div>
          <div className="content-block">
            <a
              href="architecture"
              className="banner overlay-dark"
              style={{ backgroundImage: `url('assets/images/img/Homepage/Architecture.jpg')` }}
            >
              <div className="banner-box">
                <h5 className="text-white text-center">
                  {" "}
                  Architecture of the Places
                </h5>
              </div>
            </a>
          </div>
          <div className="content-block">
            <a
              href="javascript:void(0)"
              data-bs-toggle="modal"
              data-bs-target="#comingSoon"
              className="banner overlay-dark"
              style={{ backgroundImage: `url('assets/images/img-5.png')` }}
            >
              <div className="banner-box">
                <h5 className="text-white text-center"> Take a Virtual Tour</h5>
              </div>
            </a>
          </div>
        </div>
  );
};

export default ContentBox;
