const ContentBox = () => {
  return (
<div className="pt-md-2 pt-0 text-white">
                <div>
                    <p className="lg">The Peruvanam Kummatti is celebrated during Onam time and is a festival of colourful masks and costumes.
                    </p>
                                
                </div>
                <div>
                    <p className="lg">Mantrankam is a spectacular Sanskrit play lasting 41 days and performed famously in the koothambalam of the Peruvanam Mahadeva Temple every year.
                    </p>
                           
                </div>
                <div>
                    <p className="lg">Aside from the art forms mentioned above, the arts of pottery, woodcarving and contemporary theatre are prevalent in and around Cherpu.
                    </p>
                   
                </div>
            </div>
  );
};

export default ContentBox;
