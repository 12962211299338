const Icon = () => {
  return (
    <>
      <svg
        width="17"
        height="19"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.49967 3.27083L14.2497 7.58333V16.2083H2.74967V7.58333L8.49967 3.27083ZM8.49967 0.875L0.833008 6.625V18.125H16.1663V6.625L8.49967 0.875ZM8.02051 7.10417V9.97917H7.54134V7.10417H6.58301V9.97917H6.10384V7.10417H5.14551V9.97917C5.14551 10.7746 5.78759 11.4167 6.58301 11.4167V15.25H7.54134V11.4167C8.33676 11.4167 8.97884 10.7746 8.97884 9.97917V7.10417H8.02051ZM9.45801 9.02083V11.8958H10.4163V15.25H11.3747V7.10417C10.3205 7.10417 9.45801 7.96667 9.45801 9.02083Z"
          fill="#F2F5FC"
        />
      </svg>
    </>
  );
};

export default Icon;
