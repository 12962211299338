import { useEffect, useState } from "react";
import axios from "axios";
import { assetesUrl } from '../../Constant';

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import Loader from "../../Components/Common/Loader";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import { apiBaseURL } from "../../Constant";
import { Link } from "react-router-dom";

const Attractions = () => {
  const [attraction, setAttraction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 

  const attractionList = () => {
    setIsLoading(true);
    axios
      .get(
        `${apiBaseURL}/items/attractionListing?fields=*,activities.*,activities.attractionActivities_id.*&sort=date_created`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setIsLoading(false);
        setAttraction(data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    attractionList();
  }, []);

  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Things to Do",
      pageLink: "/things-to-do",
    },
    {
      title: "Attractions",
      pageLink: "",
    },
  ];

  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="11" />
      </div>
      <SideBar activeIndex="11" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Attractions"
          breadCrumb={breadCrumb}
          previouPage="/things-to-do"
        />

        <div className="wrapper-container">
          <div className="w-100">
            <div className="row">
            {attraction.map((item, key)=>
              <div className="col-xl-3 col-lg-4 col-sm-6" key={key}>
                <Link
                  to={`/attractions-details/${item.id}`}
                  className="banner small sm overlay-dark"
                  style={{
                    backgroundImage: `url('${assetesUrl}/${item.banner}.svg')`,
                  }}
                >
                  <div className="banner-box d-flex align-items-center justify-content-between w-100">
                    <h5 className="heading">{item.name}</h5>
                    <div className="circle-30 d-flex align-items-center justify-content-center ms-auto">
                      <img
                        src="assets/images/icons/arrow-right.svg"
                        className=""
                        alt="Next"
                      />
                    </div>
                  </div>
                </Link>
              </div>
            )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default Attractions;
