import { Link } from 'react-router-dom';
import dataTemple from '../dataJson/interativeTemple.json';
import {IconTemple} from '../Components/Svg';
const IntractiveSideBar = ({activeIndex}) => {
    return (
        <div className="sidebar">
            <div className="nav-bar">
                <div className="logo">
                    <img src="/assets/images/logo-sidebar.png" className="img-fluid" alt="Peruvanam"/>
                </div>
            </div>
            <div className="sidebar-box">
                <ul className="sidebar-menu">
                    <li className="ps-0">
                        <Link to="/history" className="sidebar-menu-list">
                            Peruvanam
                        </Link>                    
                    </li>                        
                </ul>
                <button className="btn btn-primary-07 text-start active w-100 mb-1 text-start d-flex">
                    <span className="icon me-3"><img src="assets/images/icons/landmark.svg" className="w-14" alt="Landmarks"/></span>
                    Landmarks
                </button>
                <ul className="sidebar-menu mt-2">
                    <li className="ps-0">
                        <a href="javascript:void(0)" className="sidebar-menu-list">
                            Pooram
                        </a>                    
                    </li>                    
                </ul>
                <>
                { dataTemple && dataTemple.map((item, key) => 
                <Link to={`/interactive-map?templeId=${item.id}`} key={key} className={`btn btn-transparent border-light text-start w-100 mb-3 d-flex ${activeIndex == item.id ? 'active text-black bg-white' : 'text-white'}`} >
                    <span className="icon me-3">
                        <IconTemple fillColor={`${activeIndex == item.id ? '#000' : '#F2F5FC'}`}/>
                    </span>
                    {item.name}
                </Link>
                )}    
                </>         
            </div>
        </div>
    )
}
export default IntractiveSideBar;