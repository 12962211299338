import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                
                
                <div>
                    <p className="lg">Panchavadyam, as the name suggests, is an orchestral performance with five instruments: timila, ilathalam, maddalam, idakka, and kombu performed in unison in five kaalams. As in the melam, there are no rehearsals prior to the Pooram and the pramani signals the ensemble when the kaalam is to be increased during the performance. The name of the art is derived from Parishavadyam, which was performed in temple ceremonies of the past, while the leading instrument of the Panchavadyam ensemble, called the timila, was once known as paani and its usage was restricted to only temple rituals.
                   </p>
                                   
                </div>
                <div>
                    <p className="lg">Panchavadyam is only played for the Cherpu Bhagavathy deity in the Peruvanam Pooram.</p>
                                   
                </div>
                <StaticPageCrousel data={data} heading={heading}/>
            </div>
  );
};

export default ContentBox;
