import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                
                <div>
                    <p className="lg">Between the Bharathapuzha and the Periyar rivers sprawled the erstwhile province of Peruvanam. Widely believed to have been one of the earliest sixty four namboothiri settlements of Kerala that were created by Sage Parashurama, Peruvanam possesses peculiar origins, entwined with myth and lore.
                    </p>               
                </div>
                <div>
                    <p className="lg">The history of Peruvanam holds many important markers. The kuru malsaram and the longstanding rivalry between the kingdoms of Zamorin and Kochi concluded with the defeat of the former and establishment of power over the settlement by the latter. The administration of the province was carried out with the royal seal of approval, albeit autonomously by namboothiri governing committees called Sabhayogams. These committees, along with the Uraalars elected by them, were responsible for maintaining the sanctity of Peruvanam.
                    </p>                
                </div>
                <div>
                    <p className="lg">The settlement’s location in the floodplains of the Karuvannur river was conducive to practising agriculture, which consequently paved the way for land-owning namboothiris and temples to lease out cultivable lands for paddy production and to receive a portion of the produce as tax. The prosperity of the Peruvanam province was partly due to its extent, the number of Cherikalam that contributed to its economic growth and the area of agricultural lands that were brought under this settlement.
                    </p>
                    <p className="lg">Following the decline in feudal power and the eventual dissolution of the province, Peruvanam now exists as a small neighbourhood in Cherpu village in the Thrissur district.
                    </p>
                </div>
                <h2 className="mb-xl-3 mt-5">Peruvanam Mahadeva Temple<span className="text-primary"></span></h2>
                <div>
                    <p className="lg">While the Sabhayogams functioned as the administrators of the province, the main seat of power was with the central deity of the Peruvanam Mahadeva Temple- Irattaiyappan and all decisions were meted out in his name. The temple held proprietorship over the Cherikalam and ruled over the villages that were under the Peruvanam corporation.
                    </p>
                </div>
                <div>
                    <p className="lg">The evidence of this is found in detail in one noteworthy historical document, known as: The Peruvanam Grandhavari.
                    </p>
                   
                </div>
                <div>
                    <p className="lg">A Grandhavari is a compendium of different types of documents. Every household and royal family maintained a corpus of documents that contain accounts of their transactions, stories and histories. The Peruvanam Grandhavari contained accounts and transactions performed by the Peruvanam Mahadeva temple. It also speaks of its patronage to the traditional arts and crafts of the region and the large workforce that it employed, which included but was not limited to sculptors, artists, stapathis and percussionists.
                    </p>
                </div>
                <StaticPageCrousel data={data} heading={heading}/>
            </div>
  );
};

export default ContentBox;
