import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import InnerHeader from "../../../Components/Common/InnerHeader";
import HeaderMobile from "../../../Components/Common/HeaderMobile";
import SideBar from "../../../Components/Common/SideBar";
import MoveTop from "../../../Components/Common/MoveTop";
import Footer from "../../../Components/Common/Footer";
import { apiBaseURL, assetesUrl } from "../../../Constant";
import Loader from "../../../Components/Common/Loader";
import { debounce } from "../../../Utility";

import {
  IconDelicacies,
  IconKitchens,
  IconRestaurants,
} from "../../../Components/Svg";

const Food = () => {
  const [activeTab, setActiveTab] = useState("Delicacies");
  const [eateryTypeList, setEateryTypeList] = useState([]);
  const [eateryFoods, setEateryFoods] = useState([]);
  const [activeFoodList, setActiveFoodList] = useState([]);
  const [deliciousFoodList, setDeliciousFoodList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getEateryTypeList = () => {
    setIsLoading(true);
    axios
      .get(`${apiBaseURL}/items/eaterytype?sort=date_created`)
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setEateryTypeList(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getEateryFoodList = () => {
    // console.log('searchValue', searchValue)
    setIsLoading(true);
    const url = searchValue
      ? `${apiBaseURL}/items/eaterylisting?fields=*,brand_image.*,eaterytype.*&search=${searchValue}`
      : `${apiBaseURL}/items/eaterylisting?fields=*,brand_image.*,eaterytype.*`;
    axios
      .get(url)
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setEateryFoods(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getDelicaciesFoodList = () => {
    setIsLoading(true);
    const url = searchValue
      ? `${apiBaseURL}/items/delicacies?fields=*,dish_image.*&search=${searchValue}`
      : `${apiBaseURL}/items/delicacies?fields=*,dish_image.*`;
    axios
      .get(url)
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setDeliciousFoodList(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const activeListFunc = () => {
    let result = [];
    if (activeTab === "Delicacies") {
      result = deliciousFoodList;
    } else {
      result = eateryFoods.filter((item) => item.eaterytype.slug === activeTab);
    }
    setActiveFoodList(result);
  };
  const tabButtonClickHandler = (slug) => {
    setSearchValue("");
    setActiveTab(slug);
  };

  // one time call on page load for list the tab
  useEffect(() => {
    getEateryTypeList();
    getDelicaciesFoodList();
    getEateryFoodList();
  }, []);

  useEffect(() => {
    if (activeTab === "Delicacies") {
      getDelicaciesFoodList();
    } else {
      getEateryFoodList();
    }
  }, [searchValue]);

  useEffect(() => {
    activeListFunc();
  }, [activeTab, deliciousFoodList, eateryFoods]);

  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Peruvanam Cuisine",
      pageLink: "",
    },
  ];
  const searchHandler = (e) => {
    setSearchValue(e.target.value);
  };
  const searchHandlerDebounce = useCallback(debounce(searchHandler), []);

  const placeholderText =
    activeTab === "Delicacies"
      ? "delicacies"
      : activeTab === "home-kitchen-eatery"
      ? "home kitchen"
      : "restaurants";
  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="12" />
      </div>
      <SideBar activeIndex="12" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Peruvanam Cuisine"
          breadCrumb={breadCrumb}
          previouPage="/plan-a-visit"
        />
        <div className="wrapper-container">
          <div className="w-100">
            <div className="common-search-box">
              <div className="common-search">
                <input
                  type="text"
                  placeholder={`Search for ${placeholderText}...`}
                  onChange={searchHandlerDebounce}
                />
                <span className="icon cursor-pointer">
                  <img src="assets/images/icons/search.svg" className="" />
                </span>
              </div>
              <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      "Delicacies" === activeTab && " active"
                    }`}
                    id="delicacies-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#delicacies"
                    type="button"
                    role="tab"
                    aria-controls="delicacies"
                    aria-selected="true"
                    onClick={() => tabButtonClickHandler("Delicacies")}
                  >
                    Delicacies
                    <span className="icon">
                      <IconDelicacies />
                    </span>
                  </button>
                </li>
                {eateryTypeList.map((item, key) => (
                  <li className="nav-item" role="presentation" key={key}>
                    <button
                      className={`nav-link ${
                        item.slug === activeTab && " active"
                      }`}
                      id="delicacies-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#delicacies"
                      type="button"
                      role="tab"
                      aria-controls="delicacies"
                      aria-selected="true"
                      onClick={() => tabButtonClickHandler(item.slug)}
                    >
                      {item.name}
                      <span className="icon">
                        {item.slug === "delicacies-eatery" && (
                          <IconDelicacies />
                        )}
                        {item.slug === "home-kitchen-eatery" && (
                          <IconKitchens />
                        )}
                        {item.slug === "restaurant-eatery" && (
                          <IconRestaurants />
                        )}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="delicacies"
                role="tabpanel"
                aria-labelledby="delicacies-tab"
              >
                <div className="row text-decor-none pt-4">
                  {activeFoodList.length ? (
                    activeFoodList.map((item, key) => {
                      const hred =
                        "Delicacies" === activeTab
                          ? `/food/delicious/${item.id}`
                          : "";
                      return (
                        <div className="col-xl-3 col-lg-4 col-sm-6 " key={key}>
                          <Link to={hred}>
                            <div className="card-dark p-2">
                              <div>
                                {item.brand_image && (
                                  <img
                                    src={`${assetesUrl}/${item.brand_image.filename_disk}`}
                                    className="rounded-14 object-fit-cover w-100 h-125"
                                    alt="Masala Dosa"
                                  />
                                )}
                                {item.dish_image && (
                                  <img
                                    src={`${assetesUrl}/${item.dish_image.filename_disk}`}
                                    className="rounded-14 object-fit-cover w-100 h-125"
                                    alt="Masala Dosa"
                                  />
                                )}
                              </div>
                              <div className="p-2">
                                <h3 className="text-white h4-sm mb-1 text-truncate">
                                  {item.name}
                                </h3>
                                {item.location && (
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="assets/images/icons/location.svg"
                                      className=""
                                      alt="Location"
                                    />
                                    <p className="mb-0 text-grey ps-1 text-truncate">
                                      {item.location}
                                    </p>
                                  </div>
                                )}

                                {item.description && (
                                  <p className="mb-0 text-grey three-line-clmp">
                                    {item.description}
                                  </p>
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <img
                        src="/assets/images/no-data-found.jpeg"
                        height="200"
                        width="200"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default Food;
