const Icon = () => {
  return (
    <>
      <svg
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.0413 9.5415V7.62484C16.2913 5.70817 11.4997 0.916504 11.4997 0.916504C11.4997 0.916504 6.70801 5.70817 0.958008 7.62484V9.5415C0.958008 10.9694 1.84926 12.1769 3.10467 12.6657C3.06634 15.0615 2.79801 17.8694 1.91634 20.0832H21.083C20.2013 17.8694 19.933 15.0615 19.8947 12.6657C21.1501 12.1769 22.0413 10.9694 22.0413 9.5415ZM11.4997 3.51359C12.7743 4.60609 14.8443 6.23525 17.3551 7.62484H5.64426C8.15509 6.23525 10.2251 4.60609 11.4997 3.51359ZM12.458 9.5415H15.333C15.333 10.3369 14.6909 10.979 13.8955 10.979C13.1001 10.979 12.458 10.3369 12.458 9.5415ZM9.10384 10.979C8.30842 10.979 7.66634 10.3369 7.66634 9.5415H10.5413C10.5413 10.3369 9.89926 10.979 9.10384 10.979ZM5.74967 9.5415C5.74967 10.3369 5.10759 10.979 4.31217 10.979C3.51676 10.979 2.87467 10.3369 2.87467 9.5415H5.74967ZM4.46551 18.1665C4.83926 16.384 4.98301 14.5057 5.01176 12.819C5.66342 12.6753 6.24801 12.3494 6.69842 11.8798C6.93801 12.1194 7.21592 12.3111 7.51301 12.474C7.41717 14.2661 7.26384 16.3073 7.01467 18.1569H4.46551V18.1665ZM8.96009 18.1665C9.19009 16.4128 9.33384 14.544 9.42009 12.8669C10.2251 12.7903 10.963 12.4357 11.4997 11.8894C12.0363 12.4357 12.7647 12.7903 13.5793 12.8669C13.6751 14.544 13.8093 16.4128 14.0393 18.1665H8.96009ZM15.9751 18.1665C15.7163 16.3073 15.563 14.2661 15.4768 12.4836C15.7738 12.3207 16.0613 12.129 16.2913 11.8894C16.7418 12.3494 17.3263 12.6848 17.978 12.8286C18.0068 14.5153 18.1505 16.3936 18.5243 18.1761H15.9751V18.1665ZM18.6872 10.979C17.8918 10.979 17.2497 10.3369 17.2497 9.5415H20.1247C20.1247 10.3369 19.4826 10.979 18.6872 10.979Z"
          fill="#F2F5FC"
        />
      </svg>
    </>
  );
};

export default Icon;
