import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {apiBaseURL} from '../../Constant';
const Footer = () => {
const [name, setName] = useState('');
const [mobileNumber, setMobileNumber] = useState('');
const [comment, setComment] = useState('');
const [error, setError] = useState(false);
const [success, setSuccess] = useState(false);
const [loading, setLoading] = useState(false);
const [apiError, setApiError] = useState(false);

useEffect(()=> {
    setSuccess(false);
}, []);

const changesHandler = (event, fieldName) => {
    if(fieldName === 'name') setName(event.target.value);
    if(fieldName === 'mobileNumber') setMobileNumber(event.target.value);
    if(fieldName === 'comment') setComment(event.target.value);
}
const submitHandler = () => {
    if(name.trim() && mobileNumber.trim()){
        const body = {
            "fullname": name,
            "mobile_number": Number(mobileNumber),
            "comment": comment
        }
    setLoading(true);
    axios.post( `${apiBaseURL}/items/getInTouch`,body)
      .then(function (res) {
        const {
          data: { data },
        } = res;
        if(res){
            setSuccess(true);
            setName('');
            setMobileNumber('');
            setComment('');
            setLoading(false);
            setApiError(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setApiError(true);
        console.log(error);
      });
        setError(false);
    }else{
        setSuccess(false);
        setError(true);
    }
}
    return (
        <>
        <div className="row align-items-center justify-content-between footer_btm">
                <div className="content_count w-auto">
                    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.32505 6.65825C1.55255 6.88575 1.92005 6.88575 2.14755 6.65825L4.41672 4.40076L4.41672 13.2499C4.41672 13.5708 4.67922 13.8333 5.00005 13.8333C5.32089 13.8333 5.58339 13.5708 5.58339 13.2499L5.58339 4.40076L7.84672 6.66409C8.07422 6.89159 8.44172 6.89159 8.66922 6.66409C8.89672 6.43659 8.89672 6.06909 8.66922 5.84159L5.40839 2.57492C5.18089 2.34742 4.81339 2.34742 4.58589 2.57492L1.32505 5.83575C1.10339 6.06325 1.10339 6.43659 1.32505 6.65825Z" fill="#E8BD70" stroke="#E8BD70" strokeWidth="0.5"></path>
                        <g clipPath="url(#clip0_1501_6816)">
                        <path d="M15 6C16.1 6 17 6.9 17 8C17 9.1 16.1 10 15 10C13.9 10 13 9.1 13 8C13 6.9 13.9 6 15 6ZM15 16C17.7 16 20.8 17.29 21 18H9C9.23 17.28 12.31 16 15 16ZM15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14Z" fill="#E8BD70"></path>
                        </g>
                        <defs>
                        <clipPath id="clip0_1501_6816">
                        <rect width="24" height="24" fill="white" transform="translate(3)"></rect>
                        </clipPath>
                        </defs>
                    </svg>
                    <span className="text-white font-14">Website stats - <strong className="text-primary font-15">96520</strong> hits.</span>
                </div>
                <div className="content_copyright w-auto">
                    <span className="text-grey font-14">
                        Copyright © 2023 Peruvanam. All rights reserved.
                    </span>
                    <Link className="text-white font-13 ms-2" to="/tnc">Terms &amp; Conditions</Link>
                    <Link className="text-white font-13 ms-2" to="/policy">Privacy Policy</Link>
                </div>
            </div>
           {/* <!-- Modal --> */}
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <span className="icon btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="/assets/images/icons/close.svg" className="w-18" /></span>
                <div className="modal-body">                    
                    <video controls>
                        <source src="assets/images/demo.mp4" type="video/mp4" />
                    </video>
                </div>                       
            </div>
        </div>
    </div>

   {/* <!-- Modal --> */}
    <div className="modal fade" id="getTouch" tabIndex="-1" aria-labelledby="getTouchLabel" aria-hidden="true">
        <div className="modal-dialog">
        <div className="modal-content">           
            <div className="modal-header">
                <h5 className="modal-title">Get in Touch</h5>
                <span className="icon btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="/assets/images/icons/close.svg" className="w-18" /></span>
               
            </div> 
            {apiError && (
                <div className="alert alert-danger" role="alert">
                Please try after some time
              </div>
            )}
            {success && (
            <div className="alert alert-success" role="alert">
            Thanks for contacting us, We will revert you soon.
            </div>
            ) }  

                      
            <div className="modal-body p-4">
                <form>
                    <div className="mb-3">
                      <label htmlFor="fullname" className="form-label">Full Name</label>
                      <input type="text" className="form-control text-white-50" id="fullname" value={name} onChange={(e)=> changesHandler(e, 'name')}/>                      
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phonenumber" className="form-label">Phone Number</label>
                      <input type="number" className="form-control text-white-50" id="phonenumber" value={mobileNumber} onChange={(e)=> changesHandler(e, 'mobileNumber')}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control text-white-50" value={comment} onChange={(e)=> changesHandler(e, 'comment')}></textarea>                      
                    </div>
                    
                    {error && (
                        <div className="mb-3 text-danger">Please fill all mendatory fields</div>
                    )}
                <div className="mb-3">
                    <button type="button" disabled={loading} className="btn btn-primary" onClick={submitHandler}>
                    {loading && <span style={{paddingRight: '10px'}} className="pr-10"><span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span></span>}
                        Submit
                        </button>
                    </div>
                </form>
            </div>  
                 
        </div>
        </div>
        
    </div>
    
    <div className="modal fade" id="comingSoon" tabIndex="-1" aria-labelledby="comingSoon" aria-hidden="true">
        <div className="modal-dialog">
        <div className="modal-content">           
            <div>
                <span className="icon btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="/assets/images/icons/close.svg" className="w-18" /></span>
            </div>           
            <div className="modal-body p-4 text-center">
               <img src="/assets/images/coming-soon.jpeg" />
            </div>      
        </div>
        </div>
    </div>
</>
    )
}
export default Footer;