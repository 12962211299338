import HeaderVisitPlan from "../../Components/Common/HeaderVisitPlan";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import PlanContentBox from "./PlanContent/plan-a-visit";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";

const DiscoverPeruvanam = () => {
  return (
    <div className="wrapper d-md-flex">
        <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="10"/>
      </div>
      <SideBar activeIndex="10"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <HeaderVisitPlan />
        <PlanContentBox />
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default DiscoverPeruvanam;
