import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/melam";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const Melam = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Arts',
    pageLink: '/arts'
  },
  {
    title: 'Melam',
    pageLink: ''
  }
];

  const previousLink = {}

  const nextLink = {
    title: 'Panchavadyam',
    pageLink: '/panchavadyam'
  }

  let staticCrouselData = [];
  for(let i=1; i<=3 ; i++){
    staticCrouselData.push(
     { id:i,
      img: `/assets/images/img/DISCOVER-Arts/Melam/art-me-${i}.jpeg`,
    })
  }
  const newImage = [
    {
      id: 4,
      img: '/assets/images/img/DISCOVER-Arts/Melam/DISABLE RIGHT CLICK - Kuzhal_Melam page.png'
    },
    {
      id: 5,
      img: '/assets/images/img/DISCOVER-Arts/Melam/DISABLE RIGHT CLICK - Melam Ensemble.png'
    }
  ];
  staticCrouselData = [...staticCrouselData, ...newImage];
  return (
    
    <div className="wrapper d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="3"/>
      </div>
      <SideBar activeIndex="3"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Melam" breadCrumb={breadCrumb} previouPage="/arts"/>
        <ContentBox data={staticCrouselData} heading="" />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default Melam;
