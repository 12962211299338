const Loader = () => {
  return (
    <>
      <>
        <div
          className="modal fade show"
          id="loader"
          tabIndex="-1"
          aria-labelledby="loaderLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "#fff", opacity: ".5" }}
        >
          <div className="loding-content">
            <div className="spinner-border" role="status" style={{ width: "70px", height: "70px"}}>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Loader;
