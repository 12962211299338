const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
      <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2084 7.70831V6.12498C13.4584 4.54165 9.50008 0.583313 9.50008 0.583313C9.50008 0.583313 5.54175 4.54165 0.791748 6.12498V7.70831C0.791748 8.8879 1.528 9.8854 2.56508 10.2891C2.53341 12.2683 2.31175 14.5879 1.58341 16.4166H17.4167C16.6884 14.5879 16.4668 12.2683 16.4351 10.2891C17.4722 9.8854 18.2084 8.8879 18.2084 7.70831ZM9.50008 2.72873C10.553 3.63123 12.263 4.97706 14.3372 6.12498H4.663C6.73716 4.97706 8.44717 3.63123 9.50008 2.72873ZM10.2917 7.70831H12.6667C12.6667 8.3654 12.1363 8.89581 11.4792 8.89581C10.8222 8.89581 10.2917 8.3654 10.2917 7.70831ZM7.52092 8.89581C6.86383 8.89581 6.33342 8.3654 6.33342 7.70831H8.70842C8.70842 8.3654 8.178 8.89581 7.52092 8.89581ZM4.75008 7.70831C4.75008 8.3654 4.21967 8.89581 3.56258 8.89581C2.9055 8.89581 2.37508 8.3654 2.37508 7.70831H4.75008ZM3.68925 14.8333C3.998 13.3608 4.11675 11.8091 4.1405 10.4158C4.67883 10.2971 5.16175 10.0279 5.53383 9.63998C5.73175 9.8379 5.96133 9.99623 6.20675 10.1308C6.12758 11.6112 6.00092 13.2975 5.79508 14.8254H3.68925V14.8333ZM7.40217 14.8333C7.59217 13.3846 7.71092 11.8408 7.78217 10.4554C8.44717 10.3921 9.05675 10.0991 9.50008 9.6479C9.94342 10.0991 10.5451 10.3921 11.218 10.4554C11.2972 11.8408 11.408 13.3846 11.598 14.8333H7.40217ZM13.1972 14.8333C12.9834 13.2975 12.8567 11.6112 12.7855 10.1387C13.0309 10.0041 13.2684 9.84581 13.4584 9.6479C13.8305 10.0279 14.3134 10.305 14.8517 10.4237C14.8755 11.8171 14.9942 13.3687 15.303 14.8412H13.1972V14.8333ZM15.4376 8.89581C14.7805 8.89581 14.2501 8.3654 14.2501 7.70831H16.6251C16.6251 8.3654 16.0947 8.89581 15.4376 8.89581Z" fill={fill}/>
</svg>
    </>
  );
};

export default Icon;
