import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import InnerHeader from "../../../Components/Common/InnerHeader";
import HeaderMobile from "../../../Components/Common/HeaderMobile";
import SideBar from "../../../Components/Common/SideBar";
import MoveTop from "../../../Components/Common/MoveTop";
import Footer from "../../../Components/Common/Footer";
import { apiBaseURL, assetesUrl } from "../../../Constant";


import {
  IconDelicacies,
  IconKitchens,
  IconRestaurants,
} from "../../../Components/Svg";

const Food = () => {
    
  const [restrointList, setRestrointList] = useState([]);
  const [eateryFoods, setEateryFoods] = useState({});


  const routeParams = useParams();
  const { id } = routeParams;
  


  const getEateryFoodRestrorentList = () => {
    axios
      .get(
        `${apiBaseURL}/items/delicacies/${id}?fields=*,dish_image.,eatery.,eatery.eaterylisting_id.*,eatery.eaterylisting_id.brand_image*,eatery.eaterylisting_id.eaterytype.*&filter=[eater][eaterylisting_id][eaterytype][slug]=restaurant-eatery`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setEateryFoods(data);
        if(data.eatery.length){
            setRestrointList(data.eatery);
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getEateryFoodRestrorentList();
  }, []);


  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Peruvanam Cuisine",
      pageLink: "/food",
    },
    {
        title: "Delicacies",
        pageLink: "",
      },
  ];

  return (
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="12" />
      </div>
      <SideBar activeIndex="12" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Peruvanam Cuisine"
          breadCrumb={breadCrumb}
          previouPage="/plan-a-visit"
        />
        <div className="wrapper-container">
          <div className="w-100">
          <div className="row none-575 text-decor-none">                        
                        <div className="col-xxl-3 col-lg-4 col-md-5 col-sm-6 dish-view">
                            <h4 className="h4-sm mb-3 text-grey">Delicacies Name</h4>
                            <a href="javascript:void(0)">
                                <div className="card-dark p-2">
                                    <div>
                                        <img src="/assets/images/plan-a-visit/masala-dosa.png" className="rounded-14 object-fit-cover w-100 h-125" alt="Masala Dosa"/>
                                    </div>
                                    <div className="p-2">
                                        <h3 className="text-white h4-sm mb-1 text-truncate">{eateryFoods.name}</h3>                                            
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0 text-grey ps-1">{eateryFoods.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xxl-9 col-lg-8 col-md-7 dish-list">
                            <h4 className="h4-sm mb-3 text-grey">Available in {restrointList.length} Restaurants</h4>
                            <div className="row"> 
                            {restrointList && restrointList.map((item,key)=>
                             <div className="col-xxl-4 col-lg-6 col-md-12 col-sm-6" key={key}>
                             <a href="javascript:void(0)">
                                 <div className="card-dark p-2">
                                     <div>
                                         <img src={`${assetesUrl}/${item.eaterylisting_id.brand_image}.svg`} className="rounded-14 object-fit-cover w-100 h-125" alt={item.eaterylisting_id.name}/>
                                     </div>
                                     <div className="p-2">
                                         <h3 className="text-white h4-sm mb-1 text-truncate">{item.eaterylisting_id.name}</h3>                                            
                                         <div className="d-flex align-items-center">                                            
                                             <img src="/assets/images/icons/location.svg" className="" alt="Location"/>                                            
                                             <p className="mb-0 text-grey ps-1 text-truncate">{item.eaterylisting_id.location}</p>
                                         </div>
                                     </div>
                                 </div>
                             </a>                            
                         </div>
                            )}                       
                               
                                                             
                            </div>                              
                        </div>
                    </div>
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default Food;
