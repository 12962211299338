import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/peruvanam-pooram";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const PeruvanamPooram = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Peruvanam Pooram',
    pageLink: ''
  }];

  const previousLink = {
    title: 'History',
    pageLink: '/history'
  }

  const nextLink = {
    title: 'Arts',
    pageLink: '/arts'
  }
  let staticCrouselData = [];
  for(let i=1; i<=20 ; i++){
    staticCrouselData.push(
     { id:i,
      img: `/assets/images/img/DISCOVER-PeruvanamPooram/Peruvanam-Arattupuzha-Pooram/pp-${i}.jpg`,
    })
  }
  for(let i=1; i<=43 ; i++){
    staticCrouselData.push(
     { id:i,
      img: `/assets/images/img/DISCOVER-PeruvanamPooram/Other-Event/ot-${i}.jpg`,
    })
  }  

  return (
    
    <div className="wrapper d-md-flex">
      
      <div className="offcanvas offcanvas-start" id="demo"> 
        <SideBar activeIndex="2"/>
      </div>
      <SideBar activeIndex="2"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Peruvanam Pooram" breadCrumb={breadCrumb} previouPage="/discover-peruvanam"/>
        <ContentBox data={staticCrouselData} heading=""/>
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default PeruvanamPooram;
