const Icon = ({fillColor}) => {
  const fill = fillColor || '#A5A7C2';

  return (
    <>
     <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0416 5.125H8.87492V0.375H4.12492V5.125H0.958252L6.49992 10.6667L12.0416 5.125ZM5.70825 6.70833V1.95833H7.29158V6.70833H8.21783L6.49992 8.42625L4.782 6.70833H5.70825ZM0.958252 12.25H12.0416V13.8333H0.958252V12.25Z" fill={fill}/>
</svg>
    </>
  );
};

export default Icon;
