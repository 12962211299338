import Header from "../../Components/Common/Header";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/discover-peruvanam";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";

const DiscoverPeruvanam = () => {
  return (
    <div className="wrapper d-md-flex">
        <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="0"/>
      </div>
      <SideBar activeIndex="0"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <Header />
        <ContentBox />
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default DiscoverPeruvanam;
