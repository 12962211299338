import HeaderAction from "./HeaderAction";
const Header = () => {
  return (
    <div className="row align-items-center mb-4">
    <div className="col-xxl-7 col-xl-6">
        <div className="d-flex align-items-center">
            <div className="pe-md-4 pe-3 py-4"><span className="icon">
              <img src="/assets/images/plan-a-visit/plan-a-visit-icon.svg" className="w-768-26"/></span></div>
            <div className="ps-3 pe-3 text-white border-primary-white w-100">
                <h3>Plan a <span className="text-primary">Visit</span></h3>
                <p className="mb-0 main-sub">
                Everything you need to plan a visit to experience the magic of Peruvanam. Whether you're interested in history, culture, or just want to relax by the river, Peruvanam has something to offer for everyone.
                </p>
            </div>
        </div>
    </div>
    <HeaderAction />
</div>    


  );
};

export default Header;
