import { useEffect, useState } from "react";
import axios from "axios";

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import Loader from "../../Components/Common/Loader";
import { apiBaseURL, assetesUrl } from "../../Constant";
import { IconCovid, IconCultural, IconDuring, IconFood, IconWeather, IconWarning } from "../../Components/Svg";

const renderIcon = (slug) => {
  switch(slug){
    case 'foods':
      return <IconFood />
      case 'covid_19':
      return <IconCovid />
      case 'cultural_norms':
      return <IconCultural />
      case 'during_pooram':
      return <IconDuring />
      case 'weather':
      return <IconWeather />
  }
}
const tcrPosition = {
  "lat": 10.474610,
  "lng": 76.238540
};
const perumbillisseryBusStop = {
  "lat": 17.261500,
  "lng": 80.143250
};

const cochinAirPort = {
  "lat": 10.170380,
  "lng": 76.427681
}
const parakulamBusStop = {
  "lat": 17.261500,
  "lng": 80.143250
}

const coordinates = [
  tcrPosition, perumbillisseryBusStop, cochinAirPort, parakulamBusStop
]

const GettingToPeruvanam = () => {
  // https://stackoverflow.com/questions/1801732/how-do-i-link-to-google-maps-with-a-particular-longitude-and-latitude
  const [transportations, setTransportations] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [distanceLen, setDistanceLen] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const distanceCalculator =  () => {
    navigator.geolocation.getCurrentPosition( function(position) {
      const latLngA = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      setCurrentLocation(latLngA)
    });
  }
  const clickHandler = (slug) => {
    setActiveTab(slug);
  }
  
  const transportationList = () => {
    setIsLoading(true);
    axios
      .get(
        `${apiBaseURL}/items/getting_around?fields=filter[status]=published`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setIsLoading(false);
        setTransportations(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsLoading(false);
      });
  };
  const getHeadings = () => {
    setIsLoading(true);
    axios
      .get(
        `${apiBaseURL}/items/localPointer?fields=*,icon`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setHeadings(data);
        setActiveTab(data[0].slug)
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getDetails = () => {
    setIsLoading(true);
    // old url
    // ${apiBaseURL}/items/LocaleTips?fields[]=tips&fields[]=status&fields[]=6349e2c5.localPointer_id.name&fields[]=2fd8c42b.localPointer_id.id&fields[]=id&filter[_and][0][_and][0][type][localPointer_id][slug][_contains]=${activeTab}&filter[_and][1][status][_eq]=published
    axios
      .get(
        `${apiBaseURL}/items/LocaleTips?fields[]=tips&fields[]=status&fields[]=6349e2c5.localPointer_id.name&fields[]=2fd8c42b.localPointer_id.id&fields[]=id&filter[_and][0][_and][0][type][slug][_contains]=${activeTab}&filter[_and][1][status][_eq]=published&fields[]=type,type.*`
      )                
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setInfoList(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    transportationList();
    getHeadings();
  }, []);

  useEffect(()=>{
    if(activeTab) getDetails();
  }, [activeTab]);

  useEffect(()=>{
    distanceCalculator();
  }, [transportations]);

  useEffect(()=>{
    let array1 = [];
    if(Object.keys(currentLocation).length){
      coordinates.forEach(item => {
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween (currentLocation, item);
        array1.push((distance/1000).toFixed(2))
      })
      
      setDistanceLen(array1)
    }
    }, [currentLocation]);

  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Getting To Peruvanam",
      pageLink: "",
    },
  ];

  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="13" />
      </div>
      <SideBar activeIndex="13" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Getting To Peruvanam"
          breadCrumb={breadCrumb}
          previouPage="/plan-a-visit"
        />
        <div className="wrapper-container">
          <div className="w-100">
            <div className="border-bottom border-light-06 mb-3">
              <h4 className="h4-sm mb-3 text-grey">Check Transportation</h4>
              <div className="row text-decor-none">
                {transportations.map((item,key) => 
                <div className="col-xl-4 col-sm-6" key={key}>
                <div className="card-dark p-3">
                  <a
                    href="javascript:void(0)"
                    className="text-white d-flex align-items-center justify-content-between"
                  >
                    <div>
                      <div className="d-flex align-items-center mb-2">
                      <img src={`${assetesUrl}/${item.label_icon}.svg`} />
                        <h5 className="font-16 mb-0 ps-2">{item.label_name}</h5>
                      </div>
                      <p className="text-grey mb-1">
                      {item.topic}
                      </p>
                      <div className="d-flex align-items-center">
                        {distanceLen.length ? (
                       <>
                       <img src={`${assetesUrl}/${item.location_icon}.svg`} />
                         <p className="font-12 mb-0 ps-1">
                          {distanceLen.length && distanceLen[key]} { 'kms away from your location'/*item.location_text*/}
                         </p>
                         </>  
                      ) : (
                        <>
                        <IconWarning fillColor={`#fff`}/>
                        <p className="font-12 mb-0 ps-1">
                         Oops! Looks like we couldn't fetch your location
                        </p>
                        </>
                      )
                      }
                       
                      </div>
                    </div>
                    <div>
                      <img src={`${assetesUrl}/${item.direction_icon}.svg`} />
                    </div>
                  </a>
                </div>
              </div>
                )}
                
              </div>
            </div>
            <div className="border-bottom border-light-06 mb-3">
                        <h4 className="h4-sm mb-3 text-grey">Travel Information</h4>
                        
                        <div className="row text-decor-none side-accordion">
                            <div className="col-xl-3 col-lg-4">
                                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                                  {headings && headings.map((item,key)=> 
                                    <li className={`nav-item mb-3 ${activeTab === item.slug && 'active'}`} role="presentation" key={key} onClick={()=> clickHandler(item.slug)}>
                                    <button className={`nav-link ${activeTab === item.slug && 'active'}`} id="covid-tab" data-bs-toggle="tab" data-bs-target="#covid" type="button" role="tab" aria-controls="covid" aria-selected="true">
                                        <span className="pe-2">
                                          {renderIcon(item.slug) ? renderIcon(item.slug) : <img src={`${assetesUrl}/${item.icon}.svg`} />}
                                        </span>
                                        {item.name}
                                    </button>
                                </li>
                                  )}
                                    
                                </ul>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="covid1" role="tabpanel" aria-labelledby="covid-tab">
                                       
                                          {infoList && infoList.map((item,key) => 
                                          <div className="card-dark mb-3 p-3 d-flex align-items-center" key={key}>
                                            <h5 className="text-primary pe-2 mb-0 fw-bold">0{key + 1}</h5>
                                            <p className="text-white font-12 mb-0 ps-1"dangerouslySetInnerHTML={{__html: item.tips}}></p>
                                          </div>
                                          )}
                                    </div>
                                    
                                </div>
                            </div>                            
                        </div>
                    </div>
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default GettingToPeruvanam;
