const Icon = () => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3663 10.8815L1.25745 21L0.0210734 19.7627L10.1291 9.64425L9.51132 9.02563C9.02001 8.53276 8.74413 7.86523 8.74413 7.16931C8.74413 6.4734 9.02001 5.80586 9.51132 5.313L14.8182 0L16.0546 1.23725L10.7477 6.55025C10.6663 6.63151 10.6018 6.72802 10.5578 6.83424C10.5137 6.94046 10.4911 7.05432 10.4911 7.16931C10.4911 7.2843 10.5137 7.39816 10.5578 7.50439C10.6018 7.61061 10.6663 7.70711 10.7477 7.78837L11.3663 8.407L17.2927 2.4745L18.5291 3.71263L12.6018 9.64425L13.2196 10.2629C13.3837 10.4269 13.6062 10.5191 13.8382 10.5191C14.0702 10.5191 14.2927 10.4269 14.4568 10.2629L19.7663 4.94987L21.0001 6.188L15.6932 11.5001C15.201 11.9914 14.534 12.2674 13.8386 12.2674C13.1432 12.2674 12.4762 11.9914 11.9841 11.5001L11.3663 10.8815ZM5.36645 11.9368L7.71932 9.58125C7.3018 8.95366 7.05564 8.2278 7.0053 7.47569C6.95496 6.72358 7.10217 5.97139 7.43232 5.29375L2.91032 0.51975C2.67194 0.281175 2.36814 0.11868 2.03737 0.0528313C1.7066 -0.0130178 1.36373 0.0207386 1.05216 0.149828C0.740581 0.278918 0.474303 0.497538 0.287023 0.778021C0.0997436 1.0585 -0.000119188 1.38824 7.34242e-05 1.7255C-0.00624174 3.34136 0.394911 4.93273 1.16645 6.3525C2.23962 8.43864 3.65988 10.327 5.36645 11.9368ZM13.8382 14.0192C12.981 14.0212 12.1424 13.7691 11.4284 13.2948L9.94095 14.7822C11.4263 15.4514 13.0538 15.7435 14.6791 15.6327L19.7086 20.9781L20.9826 19.7794L15.2644 13.7611C14.8068 13.9262 14.3247 14.0134 13.8382 14.0192Z"
          fill="#171717"
        />
      </svg>
    </>
  );
};

export default Icon;
