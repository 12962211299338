import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import Loader from "../../Components/Common/Loader";
import { apiBaseURL, assetesUrl } from "../../Constant";
import RelatedPost from "../../Components/RelatedPost";

import GoogleMap from "../../Components/GoogleMap";

const ActivityDetails = (props) => {
  const [attraction, setAttraction] = useState({});
  const [dataRelatedPosts, setDataRelatedPosts] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [params] = useSearchParams();

  const showActivity = params.get("showActivity");
  const activityId = params.get("activityId");
  const activityName = params.get("activityName");
  
  const routeParams = useParams();
  const { id } = routeParams;

  const ActivityDetails = () => {
    setIsLoading(true);
    // `${apiBaseURL}/items/attractionListing?fields=*,activities.*,activities.attractionActivities_id.*&sort=date_created`
    axios
      .get(
        `${apiBaseURL}/items/activitiesListing/${id}`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setAttraction(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const relatedActivity = () => {
    setIsLoading(true);
    axios
      .get(  
        `${apiBaseURL}/items/activitiesListing?limit=25&fields[]=image&fields[]=name&fields[]=location&fields[]=map&fields[]=status&fields[]=id&sort[]=id&page=1&filter[_and][0][_and][0][activities][item:attractionActivities][id][_eq]=${activityId}`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setApiData(data)
        const filterRelatedActivityData = data.filter(item => item.id !== id)
        // console.log('related', filterRelatedActivityData)
        setDataRelatedPosts(filterRelatedActivityData);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ActivityDetails();
    relatedActivity();
  }, []);

  useEffect(() => {
    ActivityDetails();
  }, [id]);

  useEffect(() => {
    if(apiData.length){
     setDataRelatedPosts(apiData.filter((itm) => itm.id !== id));
    }
    
  }, [id, apiData]);

  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Things to Do",
      pageLink: "/things-to-do",
    },
    {
      title: activityName, // breacrumActivity,
      pageLink: `/activity-attractions/${activityId}?activityName=${activityName}`,
    },
    {
      title: "Activity Details",
      pageLink: "",
    },
  ];

  // console.log('attraction', attraction);
  const attarTempleData = {
    lat:  attraction.latitude && Number(attraction.latitude),
    lng: attraction.longitude && Number(attraction.longitude),
    templeName: attraction.name && attraction.name,
  };


  const mapUrl = attraction.latitude && attraction.longitude
  ? `http://www.google.com/maps/place/${attraction.latitude},${attraction.longitude}`
  : "";

  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="11" />
      </div>
      <SideBar activeIndex="11" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title={`Activity Details`}
          breadCrumb={breadCrumb}
          previouPage={`/activity-attractions/${activityId}?activityName=${activityName}`}
        />

        <div className="wrapper-container">
          <div className="w-100">
            <div className="row">
              <div className="col-lg-8">
                <div className="border-bottom border-light-06 pb-3">
                  <div>
                    <img
                      src={`${assetesUrl}/${attraction.image}.svg`}
                      className="rounded-14 object-fit-cover w-100 h-250"
                      alt="Vaidyaratnam Ayurveda Museum"
                    />
                  </div>
                  <div className="p-2">
                    <h3 className="text-white h4-sm mb-1 text-truncate">
                      {attraction.name}
                    </h3>
                    {/* <div className="d-flex align-items-center">
                      <svg
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0.499634C2.85 0.499634 0 2.91463 0 6.64963C0 9.03463 1.8375 11.8396 5.505 15.0721C5.79 15.3196 6.2175 15.3196 6.5025 15.0721C10.1625 11.8396 12 9.03463 12 6.64963C12 2.91463 9.15 0.499634 6 0.499634ZM6 7.99963C5.175 7.99963 4.5 7.32463 4.5 6.49963C4.5 5.67463 5.175 4.99963 6 4.99963C6.825 4.99963 7.5 5.67463 7.5 6.49963C7.5 7.32463 6.825 7.99963 6 7.99963Z"
                          fill="#A5A7C2"
                        />
                      </svg>
                      <p className="mb-0 text-grey ps-2 text-truncate">
                        {attraction.location}
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="border-bottom border-light-06 py-3">
                  <p className="mb-2 text-grey">Activity Description</p>
                  <div className="card-dark border-lt text-white px-3 py-3 mb-0" dangerouslySetInnerHTML={{ __html: attraction.description }} />
                  
                </div>
              </div>
              <div className="col-lg-4">
                <div className="border-bottom border-light-06 pt-lg-0 pt-3">
                  <div className="d-flex justify-content-between">
                    <p className="mb-3 text-grey">Map</p>
                    
                      { mapUrl && <div>
                        <a
                        href={mapUrl}
                        className="text-primary font-12 pe-1"
                        target='_blank'
                      >
                        View on Google Map
                      </a>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6667 0L11.56 0.02L8 1.4L4 0L0.24 1.26667C0.0999999 1.31333 0 1.43333 0 1.58667V11.6667C0 11.8533 0.146667 12 0.333333 12L0.44 11.98L4 10.6L8 12L11.76 10.7333C11.9 10.6867 12 10.5667 12 10.4133V0.333333C12 0.146667 11.8533 0 11.6667 0ZM4.66667 1.64667L7.33333 2.58V10.3533L4.66667 9.42V1.64667ZM1.33333 2.30667L3.33333 1.63333V9.43333L1.33333 10.2067V2.30667ZM10.6667 9.69333L8.66667 10.3667V2.57333L10.6667 1.8V9.69333Z"
                          fill="#E8BD70"
                        />
                      </svg>
                    </div>
                      }
                      
                  </div>

                  <div className="text-white product-map">
                    { attraction.map && <GoogleMap attarTempleData={attarTempleData}/>}
                  </div>
                </div>
              </div>
            </div>
            <RelatedPost
              data={dataRelatedPosts}
              heading={`See Related Activities`}
              showActivity={showActivity}
              activityId={activityId}
              activityName={activityName}
            />
          </div>
        </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default ActivityDetails;
