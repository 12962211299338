import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import {monthNameArr} from '../../Utility';
import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import Loader from "../../Components/Common/Loader";
import { apiBaseURL, assetesUrl } from "../../Constant";
// import Attraction from "../../Components/Attraction";
const formateTime = (unFormateTime) => {
  const arrTime = unFormateTime.split(':');
  const AmOrPm = arrTime[0] >= 12 ? 'pm' : 'am';
  let hours = (arrTime[0] % 12) || 12;
  let mints =  arrTime[1];
  const timeFinal = `${hours}:${mints}${AmOrPm}`;
  return timeFinal;
};

const EventGuide = () => {
  const [infos, setInfos] = useState([]);
  const [activeMonth, setActiveMonth] = useState('');
  const [isLoading, setIsLoading] = useState(false);

const activeMonthHandler = (month) => {
  
  if(month != activeMonth){
    setActiveMonth(month);
  }else{
    setActiveMonth('');
  }
}

  const informationList = () => {
    let url = '';
    if(activeMonth){
    url = `${apiBaseURL}/items/eventGuide?filter[_and][0][_and][0][event_date][_between][0]=2023-${activeMonth}-01&filter[_and][0][_and][0][event_date][_between][1]=2023-${activeMonth}-31&filter[_and][1][status][_eq]=published&sort=event_date`
    }else{
      url = `${apiBaseURL}/items/eventGuide?filter[_and][1][status][_eq]=published&sort=event_date`
    }
    setIsLoading(true);
    axios
      .get(url)
      .then(function (res) {
        const {
          data: { data },
        } = res;
        setInfos(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    informationList();
  }, [activeMonth]);

  const monthNameSelected = activeMonth && monthNameArr.find(item => item.id == activeMonth).text;
  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Getting To Peruvanam",
      pageLink: "",
    },
  ];
  return (
    <div className="wrapper d-md-flex">
       {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="14" />
      </div>
      <SideBar activeIndex="14" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Getting To Peruvanam"
          breadCrumb={breadCrumb}
          previouPage="/plan-a-visit"
        />
       <div className="wrapper-container">

<div className="w-100">           
   
    <div className="text-center">
        <h4 className="h4-sm text-white">Welcome to the Event Guide | <span className="text-primary">2023</span></h4>
        <p className="lg text-grey font-400">When are you visiting Peruvanam?</p>                            
        <div className="event-guide">
          {monthNameArr.map((item,key) => 
          <div className={`event-guide-boxes ${item.id == activeMonth && 'active'}`} onClick={()=>{ activeMonthHandler(item.id)}} key={key}>
              <p className="lg text-grey mb-0">{item.shortLabel}</p>
          </div>
          )}
          
        </div>
        <div className="text-grey text-underline link-opacity-100 cursor-pointer"><button className="btn btn-link me-3 text-grey" onClick={()=>activeMonthHandler('')}>See all events for the year</button></div>
    </div>   
    <div className="border-top all-events border-light pt-4">
        <h4 className="h4-sm font-500 mb-3 text-white"> {activeMonth ? monthNameSelected : 'All Events'} 
          <span className="font-14 text-grey"> Year - 2023</span>
        </h4>
        <div className="content-block-five mt-0">
          { infos.length && infos.map((item,key) => 
              <div className="content-block" key={key}>
              <div className="card-dark-gradient d-flex align-items-center">
                  <div className="pe-sm-3 pe-2 border-end border-white-primary">
                      <p className="text-number">{moment().date(moment(item.event_date).date()).format("DD")}</p>
                      <p className="text-date">{moment().month(moment(item.event_date).month()).format("MMM")}</p>                                
                  </div>
                  <div className="ps-sm-3 ps-2 block-heading">
                      <p className="lg text-value text-truncate">{item.name}</p>
                  </div>
                  <div className="event-info-box">
                      <div className="event-header border-bottom border-white-primary">
                          <h6 className="font-12 text-grey">Event Info</h6>
                      </div>
                      <div className="event-body pt-2">
                          <div className="d-flex">                                                
                              <span className="icon pe-2"><img src="assets/images/icons/calendar.svg"/></span>
                              <div>
                                  <h6 className="font-12 text-grey mb-0">Day</h6>
                                  <h5 className="font-14 text-white">{moment().date(moment(item.event_date).date()).format("dddd")}</h5>
                              </div>
                          </div>
                          <div className="d-flex">
                              <span className="icon pe-2"><img src="assets/images/icons/time.svg"/></span>
                              <div>
                                  <h6 className="font-12 text-grey mb-0">Time</h6>
                                  <h5 className="font-14 text-white">{item.event_time && formateTime(item.event_time)}-{item.event_end_time && formateTime(item.event_end_time)} </h5>
                              </div>
                          </div>
                          <div className="d-flex">
                              <span className="icon pe-2"><img src="assets/images/icons/location-white.svg"/></span>
                              <div>
                                  <h6 className="font-12 text-grey mb-0">Location</h6>
                                  <h5 className="font-14 text-white">{item.location}</h5>
                              </div>
                          </div>
                          <div className="d-flex">
                              <span className="icon pe-2"><img src="assets/images/icons/event.svg"/></span>
                              <div>
                                  <h6 className="font-12 text-grey mb-0">Event Name</h6>
                                  <h5 className="font-14 text-white mb-0">{item.name}</h5>
                              </div>
                          </div>                                            
                      </div>
                  </div>
              </div>                                
          </div>
          )}
        </div>                        
    </div>
</div>
</div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default EventGuide;
