import { Link } from 'react-router-dom';
import Carousel from 'better-react-carousel';
import { assetesUrl } from '../Constant';
const responsive = [
  {
    breakpoint: 400,
    cols: 1,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: undefined
  },
  {
    breakpoint: 600,
    cols: 2,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: undefined
  },
  {
    breakpoint: 700,
    cols: 3,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: undefined
  },
  {
    breakpoint: 1000,
    cols: 5,
    rows: 1,
    gap: 20,
    loop: true,
    autoplay: undefined
  }
]
const LeftArrow = () => ( <img className="crow-left-arrow" src='/assets/images/icons/arrow-left-owl.svg'/> )
const RightArrow = () => ( <img className="crow-right-arrow" src='/assets/images/icons/arrow-right-owl.svg'/> )

const RelatedPost = ({ data, heading, showActivity, activityId, activityName }) => {
  return (
    <>
      {data.length && (
        <div className="row text-decor-none pt-4 related-activities">
          <p className="lg mb-3 text-white">{heading}</p>
       
          <Carousel cols={6} rows={1} gap={20} loop={true}
            className="owl-attraction owl-carousel text-white"
            responsiveLayout={responsive}
            arrowLeft={LeftArrow} arrowRight={RightArrow}
        >
           {data.map((item, key) =>
           <Carousel.Item  key={key}> 
           {showActivity ? (
              <div>
              <Link to={`/activity-details/${item.id}?showActivity=${showActivity}&activityId=${activityId}&activityName=${activityName}`}>
                  <div className="card-dark p-2">
                      <div>
                          <img src={`${assetesUrl}/${item.image}.svg`} className="rounded-14 object-fit-cover w-100 h-125" alt="Offbeat itineraries"/>
                      </div>
                      <div className="p-2">
                          <h3 className="text-white h4-sm text-truncate">{item.name}</h3>
                          <div className="d-flex align-items-center">                                            
                              <img src="/assets/images/icons/location.svg" className="h-18" alt="Location"/>                                            
                              <p className="mb-0 text-grey ps-1 text-truncate">{item.location}</p>
                          </div>
                      </div>
                  </div>
              </Link>                            
          </div>
           ): (<div className="">
           <Link
               to={`/attractions-details/${item.id}`}
               className="banner bg-cover small lg overlay-dark"
               style={{backgroundImage: `url('${assetesUrl}/${item.banner}.svg')`}}
           >
               <div className="banner-box">
               <h5 className="heading">{item.name}</h5>
               </div>
           </Link>
           </div>)}
            
            </Carousel.Item>
             )}
        </Carousel>
       
          
        </div>
      )}
    </>
  );
};

export default RelatedPost;
