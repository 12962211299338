import { Link } from "react-router-dom";
import {IconRightArrow, IconHistory, IconStar, IconArt, IconArchitecture, IconVertualTour, IconThingToDo, 
  IconFood2, IconThingDo, IconEventGuide, IconDownload
} from '../Svg';
const SideBar = ({ activeIndex }) => {
  return (
    <div className="sidebar">
      <div className="close-icon d-md-none">
        <img src="/assets/images/icons/close.svg" className="w-14" alt="Close" />
      </div>
      <div className="nav-bar">
        <Link to="/" className="logo">
          <img
            src="/assets/images/logo-sidebar.png"
            className="img-fluid"
            alt="Peruvanam"
          />
        </Link>
      </div>
      <div className="sidebar-box">
        <Link
          className={`btn btn-primary-07  w-100 mb-1 text-start d-flex ${
            activeIndex < 10 ? "active" : ""
          }`}
          to="/discover-peruvanam"
        >
          Discover Peruvanam
          <span className="icon ms-auto">
          <IconRightArrow fillColor={`${activeIndex < 10 ? "#E8BD70" : ""}`} />
          </span>
        </Link>
        <ul className={`sidebar-menu ${
            activeIndex < 10 ? "" : "light-white"
          }`}>
          <li>
            <Link
              to="/history"
              className={`sidebar-menu-list ${
                activeIndex == 1 ? "active" : ""
              }`}
            >
              <span className="icon">
              <IconHistory fillColor={`${activeIndex == 1 ? '#E8BD70' : activeIndex < 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              History
            </Link>
          </li>
          <li>
            <Link
              to="/peruvanam-pooram"
              className={`sidebar-menu-list ${
                activeIndex == '2' ? "active" : ""
              }`}
            >
              <span className="icon">
              
              <IconStar fillColor={`${activeIndex == 2 ? '#E8BD70' : activeIndex < 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              Peruvanam Pooram
            </Link>
          </li>
          <li>
            <Link
              to="/arts"
              className={`sidebar-menu-list ${
                activeIndex == '3' ? "active" : ""
              }`}
            >
              <span className="icon">
              <IconArt fillColor={`${activeIndex == 3 ? '#E8BD70' : activeIndex < 10 ? '#F2F5FC': ''}`}/>
                
              </span>{" "}
              Arts
            </Link>
          </li>
          <li>
            <Link
              to="/architecture"
              className={`sidebar-menu-list ${
                activeIndex == '4' ? "active" : ""
              }`}
            >
              <span className="icon">
              <IconArchitecture fillColor={`${activeIndex == 4 ? '#E8BD70' : activeIndex < 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              Architecture
            </Link>
          </li>
          <li>
          <Link
              to="/virtual-tour"
              className={`sidebar-menu-list ${
                activeIndex == '5' ? "active" : ""
              }`}
            >
              <span className="icon">
              <IconVertualTour fillColor={`${activeIndex == 5 ? '#E8BD70' : activeIndex < 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              Take a Virtual Tour
            </Link>
          </li>
        </ul>
        <Link to="/plan-a-visit" className={`btn btn-primary-07 w-100 mb-1 text-start d-flex ${
            activeIndex >= 10  ? "active" : ""
        }`}>
          Plan a Visit
          <span className="icon ms-auto">
          <IconRightArrow fillColor={`${activeIndex >= 10 ? "#E8BD70" : ""}`} />
          </span>
        </Link>
        <ul className={`sidebar-menu ${
            activeIndex >= 10 ? "" : "light-white"
          }`}>
          <li>
            <Link to="/things-to-do" className={`sidebar-menu-list ${
                activeIndex == '11' ? "active" : ""
              }`}>
              <span className="icon">
              <IconThingToDo fillColor={`${activeIndex == 11 ? '#E8BD70' : activeIndex >= 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              Things to Do
            </Link>
          </li>
          <li>
            <Link to="/food" className={`sidebar-menu-list ${
                activeIndex == 12 ? "active" : ""
              }`}>
              <span className="icon">
              <IconFood2 fillColor={`${activeIndex == 12 ? '#E8BD70' : activeIndex >= 10 ? '#F2F5FC': ''}`}/>
             
              </span>{" "}
              Peruvanam Cuisine
            </Link>
          </li>
          <li>
            <Link to="/getting-to-peruvanam" className={`sidebar-menu-list ${
                activeIndex == 13 ? "active" : ""
              }`}>
              <span className="icon">
              <IconThingDo fillColor={`${activeIndex == 13 ? '#E8BD70' : activeIndex >= 10 ? '#F2F5FC': ''}`}/>
              </span>{" "}
              Getting to Peruvanam
            </Link>
          </li>
          <li>
            <Link to="/event-guide" className={`sidebar-menu-list ${
                activeIndex == '14' ? "active" : ""
              }`}>
              <span className="icon">
              <IconEventGuide fillColor={`${activeIndex == 14 ? '#E8BD70' : activeIndex >= 10 ? '#F2F5FC': ''}`}/>
      
              </span>{" "}
              Event Guide
            </Link>
          </li>
          <li>
            <Link to="/download" className={`sidebar-menu-list ${
                activeIndex == '15' ? "active" : ""
              }`}>
              <span className="icon">
              <IconDownload fillColor={`${activeIndex == 15 ? '#E8BD70' : activeIndex >= 10 ? '#F2F5FC': ''}`}/>
        
              </span>{" "}
              Useful Downloads
            </Link>
          </li>
        </ul>
        <div className="pt-2 mb-5 text-center">
          <Link
            href=""
            className="text-white text-decoration-underline"
            data-bs-toggle="modal"
            data-bs-target="#getTouch"
          >
            Get in Touch
          </Link>
        </div>

        <div className="text-center">
          <p className="text-grey">Install our App</p>
          <div className="d-flex justify-content-between">
            <Link href="javascript:void(0)" className="w-50">
              <img
                src="/assets/images/google-play-badge.png"
                className="img-fluid pe-1"
              />
            </Link>
            <Link href="javascript:void(0)" className="w-50 text-end">
              <img
                src="/assets/images/app-store-badge.png"
                className="img-fluid ps-1"
              />
            </Link>
          </div>
          <div
            className="d-flex justify-content-between align-items-center mt-3 pt-3"
            style={{ borderTop: "1px solid #FFFFFF0F" }}
          >
            <span className="font-14 text-grey">Follow us on -</span>
            <Link
              href="javascript:void(0)"
              className="w-50 text-end"
              style={{ textDecoration: "none" }}
            >
              <span className="font-13 me-1 text-white">Instagram</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 102 102"
              >
                <defs>
                  <radialGradient
                    id="a"
                    cx="6.601"
                    cy="99.766"
                    r="129.502"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset=".09" stopColor="#fa8f21"></stop>
                    <stop offset=".78" stopColor="#d82d7e"></stop>
                  </radialGradient>
                  <radialGradient
                    id="b"
                    cx="70.652"
                    cy="96.49"
                    r="113.963"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop
                      offset=".64"
                      stopColor="#8c3aaa"
                      stopOpacity="0"
                    ></stop>
                    <stop offset="1" stopColor="#8c3aaa"></stop>
                  </radialGradient>
                </defs>
                <path
                  fill="url(#a)"
                  d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
                  data-name="Path 16"
                ></path>
                <path
                  fill="url(#b)"
                  d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
                  data-name="Path 17"
                ></path>
                <path
                  fill="#fff"
                  d="M461.114,477.413a12.631,12.631,0,1,1,12.629,12.632,12.631,12.631,0,0,1-12.629-12.632m-6.829,0a19.458,19.458,0,1,0,19.458-19.458,19.457,19.457,0,0,0-19.458,19.458m35.139-20.229a4.547,4.547,0,1,0,4.549-4.545h0a4.549,4.549,0,0,0-4.547,4.545m-30.99,51.074a20.943,20.943,0,0,1-7.037-1.3,12.547,12.547,0,0,1-7.193-7.19,20.923,20.923,0,0,1-1.3-7.037c-.184-3.994-.22-5.194-.22-15.313s.04-11.316.22-15.314a21.082,21.082,0,0,1,1.3-7.037,12.54,12.54,0,0,1,7.193-7.193,20.924,20.924,0,0,1,7.037-1.3c3.994-.184,5.194-.22,15.309-.22s11.316.039,15.314.221a21.082,21.082,0,0,1,7.037,1.3,12.541,12.541,0,0,1,7.193,7.193,20.926,20.926,0,0,1,1.3,7.037c.184,4,.22,5.194.22,15.314s-.037,11.316-.22,15.314a21.023,21.023,0,0,1-1.3,7.037,12.547,12.547,0,0,1-7.193,7.19,20.925,20.925,0,0,1-7.037,1.3c-3.994.184-5.194.22-15.314.22s-11.316-.037-15.309-.22m-.314-68.509a27.786,27.786,0,0,0-9.2,1.76,19.373,19.373,0,0,0-11.083,11.083,27.794,27.794,0,0,0-1.76,9.2c-.187,4.04-.229,5.332-.229,15.623s.043,11.582.229,15.623a27.793,27.793,0,0,0,1.76,9.2,19.374,19.374,0,0,0,11.083,11.083,27.813,27.813,0,0,0,9.2,1.76c4.042.184,5.332.229,15.623.229s11.582-.043,15.623-.229a27.8,27.8,0,0,0,9.2-1.76,19.374,19.374,0,0,0,11.083-11.083,27.716,27.716,0,0,0,1.76-9.2c.184-4.043.226-5.332.226-15.623s-.043-11.582-.226-15.623a27.786,27.786,0,0,0-1.76-9.2,19.379,19.379,0,0,0-11.08-11.083,27.748,27.748,0,0,0-9.2-1.76c-4.041-.185-5.332-.229-15.621-.229s-11.583.043-15.626.229"
                  data-name="Path 18"
                  transform="translate(-422.637 -426.196)"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
