import {Link} from 'react-router-dom' 
const ContentBox = () => {
  return (
    <div className="row text-white py-md-4">
    <div className="col-xl-4 col-sm-6">
        <Link to="/mana" className="shape-box">                    
            <img src="/assets/images/shapes/mana-1.png" alt="Mana" className="img-fluid"/>
            <div className="shape-box-text">Mana</div>
        </Link>                    
    </div>
    <div className="col-xl-4 col-sm-6">
        <Link to="/temples" className="shape-box">
            <img src="/assets/images/shapes/mana-2.png" alt="Mana" className="img-fluid"/>                        
            <div className="shape-box-text">Temples</div>
        </Link>
    </div>
    <div className="col-xl-4 col-sm-6">
        <Link to="/peruvanam-kizhakke-nadavazhi" className="shape-box">                    
            <img src="/assets/images/shapes/mana-3.png" alt="Mana" className="img-fluid"/>
            <div className="shape-box-text">Peruvanam Kizhakke Nadavazhi</div>
        </Link>                    
    </div>                            
</div>
  );
};
export default ContentBox;
