import InnerHeader from "../Components/Common/InnerHeader";
import HeaderMobile from "../Components/Common/HeaderMobile";
import SideBar from "../Components/Common/SideBar";
import MoveTop from "../Components/Common/MoveTop";
import Footer from "../Components/Common/Footer";

const Tnc = () => {
  const breadCrumb = [{
    title: 'Terms & Conditions',
    pageLink: ''
  },
  ];

  return (
    
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="1"/>
      </div>
      <SideBar activeIndex="1"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Terms & Conditions" breadCrumb={breadCrumb} previouPage="/"/>
        <div className="pt-md-2 pt-0 text-white">  
        Content here
        Terms & Conditions
        </div>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default Tnc;
