
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import dataTemple from '../dataJson/temple.json';
import dataEventTemple from '../dataJson/eventTemple.json';

let mapRes = null;
let mapsRes = null;
let markersRes = [];
const options = {
  styles: [
    { elementType: "geometry", stylers: [{ color: "#2e3338" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#474f57" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#474f57" }],
    },
  ],
}

const GoogleMaps = ({ activeIndex, attarTempleData, eventIndex }) => {
  const [showMarker, setShowMarker] = useState([]);

  useEffect(()=>{
    if(activeIndex){
      setShowMarker(dataTemple.filter((item) => item.id == activeIndex)); 
    }
    if(attarTempleData){
      setShowMarker([attarTempleData])
    }
    if(eventIndex){
      const temples = getTempleByEvent(eventIndex);
      setShowMarker(temples);
    }


    if(mapRes && mapsRes){
      ModelsMap(mapRes, mapsRes)
    }
    
  },[activeIndex, attarTempleData, eventIndex]);


const getTempleByEvent = (index) => {
  const templesId = dataEventTemple[index] || [1];
  // console.log('templesId', templesId);
  const showTemples = dataTemple.filter(item => templesId.some((id)=>item.id == id));
  // console.log('showTemples', showTemples);
  return showTemples;
}

  const ModelsMap = (map, maps) => {
    let dataArray = []
    if(activeIndex)
    dataArray = dataTemple.filter((item) => item.id == activeIndex)

    if(attarTempleData){
      dataArray = [attarTempleData];
    }
    if(eventIndex){
      dataArray = getTempleByEvent(eventIndex);
    }
   

    for (let i = 0; i < markersRes.length; i++) {
      markersRes[i].setMap(null);
    }
    markersRes = [];
    mapRes = map;
    mapsRes = maps;

    for (let i = 0; i < dataArray.length; i++) {
      let marker = new mapsRes.Marker({
        position: { lat: dataArray[i].lat, lng: dataArray[i].lng },
        map: mapRes,
        icon: 'https://devamela.org/assets/images/markerIcon.svg',
        label:{
          text: `${dataArray[i].templeName}`,
          color: '#ffffff',
          className: 'marker-title'
        }
      });
      const infowindow = new mapsRes.InfoWindow({
        content: `<div class="title">${dataArray[i].templeName}</div>`,
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map: mapRes,
        });
      });
      markersRes.push(marker);
    }
  };

  return (
    <>
    {showMarker.length > 0 &&
      <div id="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDqmRGjc63fjiQ6D5M2u1yxy3EysHR4fkY' }}
        center={{ lat: showMarker[0].lat, lng: showMarker[0].lng }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
        options={options}
      ></GoogleMapReact>
    </div>
    }
    
    </>
  );
};

export default GoogleMaps;

