const Icon = ({fillColor}) => {
  const addColor = fillColor || '#A5A7C2';
  return (
    <>
      <svg
        width="17"
        height="12"
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9166 11.25C10.5916 10.925 10.5916 10.4 10.9166 10.075L14.1416 6.83334H1.4999C1.04157 6.83334 0.666569 6.45834 0.666569 6C0.666569 5.54167 1.04157 5.16667 1.4999 5.16667H14.1416L10.9082 1.93334C10.5832 1.60834 10.5832 1.08334 10.9082 0.758337C11.2332 0.433337 11.7582 0.433337 12.0832 0.758337L16.7499 5.41667C17.0749 5.74167 17.0749 6.26667 16.7499 6.59167L12.0916 11.25C11.7666 11.5667 11.2332 11.5667 10.9166 11.25Z"
          fill={addColor}
        />
      </svg>
    </>
  );
};

export default Icon;
