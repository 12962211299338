import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/history";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const History = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'History',
    pageLink: ''
  }];

  const previousLink = {}

  const nextLink = {
    title: 'Peruvanam Pooram',
    pageLink: '/peruvanam-pooram'
  }

  const staticCrouselData = [{
    id:1,
    img: '/assets/images/img/DISCOVER-History/history-1.jpg',
    name: 'xyz'
  },
  {
    id:2,
    img: '/assets/images/img/DISCOVER-History/history-2.jpg',
    name: 'xyz'
  },
  {
    id:3,
    img: '/assets/images/img/DISCOVER-History/history-3.jpg',
    name: 'xyz'
  }];
  return (
    
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="1"/>
      </div>
      <SideBar activeIndex="1"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="History" breadCrumb={breadCrumb} previouPage="/discover-peruvanam"/>
        <ContentBox data={staticCrouselData} heading=""/>
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default History;
