const Icon = ({ fillColor }) => {
  const fill = fillColor || "#A5A7C2";

  return (
    <>
      <svg
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4166 4.91669V1.75002C16.4166 0.879187 15.7041 0.166687 14.8333 0.166687H2.16659C1.29575 0.166687 0.591169 0.879187 0.591169 1.75002V4.91669C1.462 4.91669 2.16659 5.62919 2.16659 6.50002C2.16659 7.37085 1.462 8.08335 0.583252 8.08335V11.25C0.583252 12.1209 1.29575 12.8334 2.16659 12.8334H14.8333C15.7041 12.8334 16.4166 12.1209 16.4166 11.25V8.08335C15.5458 8.08335 14.8333 7.37085 14.8333 6.50002C14.8333 5.62919 15.5458 4.91669 16.4166 4.91669ZM14.8333 3.76085C13.8912 4.3071 13.2499 5.33627 13.2499 6.50002C13.2499 7.66377 13.8912 8.69293 14.8333 9.23919V11.25H2.16659V9.23919C3.10867 8.69293 3.74992 7.66377 3.74992 6.50002C3.74992 5.32835 3.11659 4.3071 2.1745 3.76085L2.16659 1.75002H14.8333V3.76085ZM6.18033 9.66669L8.49992 8.17835L10.8195 9.66669L10.1149 7.00669L12.2445 5.26502L9.49742 5.09877L8.49992 2.54169L7.4945 5.09085L4.74742 5.2571L6.877 6.99877L6.18033 9.66669Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default Icon;
