import { useEffect, useState } from "react";
import axios from 'axios';

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import { apiBaseURL, assetesUrl } from "../../Constant";
import Loader from "../../Components/Common/Loader";
import CommingSoon from "../../Components/ComingSoon";

const UsefullDownload = () => {
const [downloads, setDownloads] = useState([]);
const [isLoading, setIsLoading] = useState(false);


const getUsefullDownloadList = () => {
  setIsLoading(true);
  axios.get(`${apiBaseURL}/items/usefullDownload?fields=*,banner.*,file.*`)
  .then(function (res) {
    const {data:{data}} = res;
    setDownloads(data);
    setIsLoading(false);
  })
  .catch(function (error) {
    setIsLoading(false);
    console.log(error);
  });
}
  useEffect(()=>{
    getUsefullDownloadList();
  }, []);
  


  
  const breadCrumb = [{
    title: 'Plan a Trip',
    pageLink: '/plan-a-visit'
  },
  {
    title: 'Useful Downloads',
    pageLink: ''
  }];

  return (
    
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="15"/>
      </div>
      <SideBar activeIndex="15"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Useful Downloads" breadCrumb={breadCrumb} previouPage="/plan-a-visit"/>
        <div className="wrapper-container">
        <div className="w-100">
          {/**                    
                    <div className="row">
                      {downloads.map((item,key)=> 
                      <div className="col-xl-3 col-lg-4 col-sm-6" key={key}>                            
                      <div className="card-dark p-2">
                          <div>
                              <img src={`${assetesUrl}/${item.banner.filename_disk}`} className="rounded-14 object-fit-cover w-100 h-125" alt={`${assetesUrl}/${item.banner.title}`}/>
                          </div>
                          <div className="p-2">
                              <h3 className="text-white h4-sm text-truncate">{item.name}</h3>
                              <div className="border-top border-light-06 pt-3 d-flex align-items-center justify-content-end">                                                                                        
                                  <a href={`${assetesUrl}/${item.file.filename_disk}`} target="_blank" className="text-primary"><p className="mb-0 pe-2 text-primary font-16">Download</p></a>
                                  <a href={`${assetesUrl}/${item.file.filename_disk}`} target="_blank"><img src="/assets/images/icons/download.svg" className="" alt="Download"/></a>
                              </div>
                          </div>
                      </div>
                  </div>
                      )}                        
                        
                    </div>   
                     */}     
                     <CommingSoon />    
                </div>
                </div>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default UsefullDownload;
