import { useEffect, useState } from "react";
import axios from 'axios';

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import { apiBaseURL } from "../../Constant";
import Attraction from "../../Components/Attraction";
import Loader from "../../Components/Common/Loader";


const ThingsToDo = () => {
const [attraction, setAttraction] = useState([]);
const [activities, setActivities] = useState([]);
const [isLoading, setIsLoading] = useState(false);

const attractionList = () => {
  setIsLoading(true);
  axios.get(`${apiBaseURL}/items/attractionListing?fields=*,activities.*,activities.attractionActivities_id.*&sort=date_created&limit=6`)
  .then(function (res) {
    const {data:{data}} = res;
    setAttraction(data);
    setIsLoading(false);
  })
  .catch(function (error) {
    console.log(error);
    setIsLoading(false);
  });
}
const activitiesList = () => {
  setIsLoading(true)
  axios.get(`${apiBaseURL}/items/attractionActivities?sort=date_created&fields=*,icon&limit=8`)
  .then(function (res) {
    const {data:{data}} = res;
    setActivities(data);
    setIsLoading(false);
  })
  .catch(function (error) {
    console.log(error);
    setIsLoading(false);
  });
}
  useEffect(()=>{
    attractionList()
    activitiesList();
  }, []);
  


  
  const breadCrumb = [{
    title: 'Plan a Trip',
    pageLink: '/plan-a-visit'
  },
  {
    title: 'Things to Do',
    pageLink: ''
  }];

  return (
    
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="11"/>
      </div>
      <SideBar activeIndex="11"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Things to Do" breadCrumb={breadCrumb} previouPage="/plan-a-visit"/>
        <Attraction attractionData={attraction} activityData={activities}/> 
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default ThingsToDo;
