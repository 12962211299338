
const ComingSoon = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">                
               <h1 className="text-center mt-4">Coming Soon...</h1>
                
            </div>
  );
};

export default ComingSoon;
