import StaticPageCrousel from '../../../Components/StaticPageCrousel';
const ContentBox = ({data, heading}) => {
  return (
<div className="pt-md-2 pt-0 text-white">               
                <div>
                    <p className="lg">Nettipattam refers to the complex, embellished caparisons that adorn the forehead of the elephants. These are traditionally made with folded copper plates that are plated with gold. The highlight of a nettipattam is that no curve or crevice on it is arbitrary; each intricate detail on the ornament is designed specifically to suit the anatomy and zoometrics of an elephant’s head and trunk region.
                    </p>
                                  
                </div>
                <div>
                    <p className="lg">The first standardised design of nettipattam was created by Shankaran Namboothiri of the Kirangaatu Mana. The caparison for the elephant at Urakathamma Thiruvadi Temple was made of solid gold, while others are usually made from brass or gold-plated on a skeleton of another metal. The ornament is said to have been derived from the padachatta which were armours used on war elephants in the past.
                    </p>
                               
                </div>
                <div>
                    <p className="lg">It takes about a month and a team of goldsmiths, fabric workers, tailors and metal workers to produce one nettipattam. Three standard sizes of caparisons are available- oru kol 4, oru kol 6, oru kol 8 (28 and ¼ inch, ratios
                        1:4 and 1:6). In Peruvanam, around ten Christian families continue to practise the art today. All elephants of the Peruvanam Pooram don the nettipattam.
                        It is also hard to find a household in the region that does not have a miniature version of the nettipattam as a souvenir in their homes.
                    </p>
                                 
                </div>
                <StaticPageCrousel data={data} heading={heading}/>
            </div>
  );
};

export default ContentBox;
