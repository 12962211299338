import { Link } from "react-router-dom";
const MoveTop = () => {

  const moveTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <>
    <div id="moveTop" style={{display:'none'}}>
      <div className="click-top">
      <a href="javascript:void(0)" className="icon" onClick={moveTop}>
        <img src="/assets/images/icons/arrow-down.svg" />
      </a>
    </div>
  </div>
  </>
  );
};

export default MoveTop;
