import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/peruvanam-kizhakke-nadavazhi";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage"
import Footer from "../../Components/Common/Footer";

const Temples = () => {
  const breadCrumb = [{
    title: 'Discover Peruvanam',
    pageLink: '/discover-peruvanam'
  },
  {
    title: 'Architecture',
    pageLink: '/architecture'
  },
  {
    title: 'Peruvanam Kizhakke Nadavazhi',
    pageLink: ''
  }
];

  const previousLink = {
    title: 'Temples',
    pageLink: '/temples'
  }

  const nextLink = {}

  let staticCrouselData = [];
  for(let i=1; i<=7 ; i++){
    staticCrouselData.push(
     { id:i,
      img: `/assets/images/img/DISCOVER-Architecture/Nadavazhi/nad-${i}.jpg`,
    })
  }

  return (
    
    <div className="wrapper d-md-flex">
       <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="4"/>
      </div>
      <SideBar activeIndex="4"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader title="Peruvanam Kizhakke Nadavazhi" breadCrumb={breadCrumb} previouPage="/architecture"/>
        <ContentBox data={staticCrouselData} heading="" />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink}/>
        <Footer />
      </div>
     <MoveTop/>
    </div>
  );
};

export default Temples;
