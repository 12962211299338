const Icon = () => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.72859 19.2859C1.72859 19.8637 1.72859 20.4243 1.72859 21C1.1428 21 0.58217 21 0 21C0 16.9301 0 12.8601 0 8.76538C3.4883 8.76538 6.97202 8.76538 10.4893 8.76538C10.4893 8.6421 10.4893 8.54296 10.4893 8.44375C10.4893 6.26431 10.4888 4.0848 10.4895 1.90535C10.4898 0.732182 11.2193 0.00132522 12.3925 0.000778218C14.6239 -0.000315776 16.8553 -0.000179511 19.0867 0.000709359C20.2705 0.00118798 20.9997 0.734439 20.9997 1.92457C21.0002 8.18092 20.9999 14.4373 20.9999 20.6936C20.9999 20.7868 20.9999 20.8801 20.9999 20.9945C17.4945 20.9945 14.005 20.9945 10.4947 20.9945C10.4947 20.4276 10.4947 19.8668 10.4947 19.2859C7.57307 19.2859 4.672 19.2859 1.72859 19.2859ZM12.2551 8.77147C12.2551 12.2781 12.2551 15.7595 12.2551 19.2437C14.5913 19.2437 16.91 19.2437 19.2343 19.2437C19.2343 16.9096 19.2343 14.5975 19.2343 12.2545C18.6529 12.2545 18.0852 12.2545 17.516 12.2545C17.516 11.6574 17.516 11.0914 17.516 10.5045C18.0973 10.5045 18.6582 10.5045 19.2322 10.5045C19.2322 9.91633 19.2322 9.34937 19.2322 8.7714C16.9016 8.77147 14.5877 8.77147 12.2551 8.77147ZM10.4708 17.4961C10.4708 15.1603 10.4708 12.8407 10.4708 10.5279C7.55084 10.5279 4.64936 10.5279 1.74685 10.5279C1.74685 12.8592 1.74685 15.1708 1.74685 17.4961C4.66632 17.4961 7.56849 17.4961 10.4708 17.4961ZM12.253 1.76327C12.253 3.52282 12.253 5.25325 12.253 6.99332C14.5863 6.99332 16.9043 6.99332 19.2266 6.99332C19.2266 6.40988 19.2266 5.84469 19.2266 5.25277C18.644 5.25277 18.0833 5.25277 17.5171 5.25277C17.5171 4.65942 17.5171 4.09341 17.5171 3.49937C18.0985 3.49937 18.6589 3.49937 19.2284 3.49937C19.2284 2.90506 19.2284 2.33926 19.2284 1.76327C16.8959 1.76327 14.584 1.76327 12.253 1.76327Z"
          fill="#F2F5FC"
        />
        <path
          d="M9.61137 5.24613C7.27161 5.24613 4.95859 5.24613 2.62738 5.24613C2.60549 4.82487 2.73633 4.46153 2.95512 4.12977C3.44332 3.38955 4.16905 2.99476 5.00236 2.78088C5.19168 2.73226 5.24646 2.66341 5.24427 2.47586C5.23483 1.6648 5.24024 0.853535 5.24024 0.0214844C5.82494 0.0214844 6.39117 0.0214844 6.99229 0.0214844C6.99229 0.251975 6.99229 0.479731 6.99229 0.707555C6.99229 1.30583 6.99721 1.90418 6.98914 2.50232C6.98695 2.66594 7.03079 2.73425 7.2026 2.77589C7.968 2.96146 8.64058 3.31461 9.13918 3.94516C9.43252 4.3163 9.60009 4.7368 9.61137 5.24613Z"
          fill="#F2F5FC"
        />
        <path
          d="M9.15885 13.5957C9.15885 14.4594 9.15885 15.3112 9.15885 16.1813C7.13302 16.1813 5.11088 16.1813 3.06836 16.1813C3.06836 15.3304 3.06836 14.4729 3.06836 13.5957C5.08455 13.5957 7.10553 13.5957 9.15885 13.5957Z"
          fill="#F2F5FC"
        />
        <path
          d="M6.7618 12.0409C6.76098 12.4026 6.47545 12.6927 6.1171 12.6959C5.75266 12.6992 5.45448 12.3944 5.46104 12.0253C5.4674 11.6683 5.76394 11.3795 6.11942 11.3838C6.47593 11.3883 6.76262 11.6815 6.7618 12.0409Z"
          fill="#F2F5FC"
        />
        <path
          d="M3.05081 12.0319C3.05437 11.6755 3.34865 11.3835 3.70407 11.3838C4.05893 11.3841 4.35322 11.6779 4.3554 12.0339C4.35766 12.4028 4.05319 12.7056 3.68957 12.6962C3.33512 12.6871 3.04726 12.3877 3.05081 12.0319Z"
          fill="#F2F5FC"
        />
        <path
          d="M9.16567 12.0484C9.16116 12.4096 8.87111 12.6981 8.51419 12.6964C8.14846 12.6946 7.85739 12.389 7.86744 12.0174C7.87708 11.6604 8.1769 11.3747 8.53232 11.384C8.88991 11.3933 9.17018 11.6873 9.16567 12.0484Z"
          fill="#F2F5FC"
        />
      </svg>
    </>
  );
};

export default Icon;
