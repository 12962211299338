import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import ContentBox from "./Content/architecture";
import MoveTop from "../../Components/Common/MoveTop";
import NextPreviousPage from "../../Components/Common/NextPreviousPage";
import Footer from "../../Components/Common/Footer";

const Architecture = () => {
  const breadCrumb = [
    {
      title: "Discover Peruvanam",
      pageLink: "/discover-peruvanam",
    },
    {
      title: "Architecture",
      pageLink: "",
    }
  ];

  const previousLink = {
    title: "Arts",
    pageLink: "/arts",
  };

  const nextLink = {};
  return (
    <div className="wrapper d-md-flex">
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="4"/>
      </div>
      <SideBar activeIndex="4"/>
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title="Architecture of the Places"
          breadCrumb={breadCrumb}
          previouPage="/discover-peruvanam"
        />
        <ContentBox />
        <NextPreviousPage previousLink={previousLink} nextLink={nextLink} />
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default Architecture;
