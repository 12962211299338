import { Link } from "react-router-dom";
const HeaderAction = () => {
  return (
    <div className="col-xxl-5 col-xl-6 mt-xl-0 mt-3 text-end d-md-block d-none">
      <button
        className="btn btn-primary me-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <span className="icon pe-1">
          <img src="/assets/images/icons/watch-docuseries.svg" />
        </span>{" "}
        Watch Docuseries
      </button>
      <Link to="/interactive-map" className="btn btn-primary">
        <span className="icon pe-1">
          <img src="/assets/images/icons/map.svg" />
        </span>{" "}
        View Interactive Map
      </Link>
    </div>
  );
};

export default HeaderAction;
