import { useEffect, useState } from "react";
import axios from "axios";
import { assetesUrl } from '../../Constant';

import InnerHeader from "../../Components/Common/InnerHeader";
import HeaderMobile from "../../Components/Common/HeaderMobile";
import SideBar from "../../Components/Common/SideBar";
import MoveTop from "../../Components/Common/MoveTop";
import Footer from "../../Components/Common/Footer";
import { apiBaseURL } from "../../Constant";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
const ActivityAttractions = () => {
  const [attraction, setAttraction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const routeParams = useParams();
  const { id: activityId } = routeParams; 
  const [params] = useSearchParams();

  const activityName = params.get("activityName");

  // const pageTitle = attraction.length ? attraction[0].activities[0].attractionActivities_id.name : '';
  const attractionList = () => {
    setIsLoading(true);
    // `${apiBaseURL}/items/attractionListing?fields=*,activities.*,activities.attractionActivities_id.*&sort=date_created`
    axios
      .get(  
        `${apiBaseURL}/items/activitiesListing?limit=25&fields[]=image&fields[]=name&fields[]=location&fields[]=map&fields[]=status&fields[]=id&sort[]=id&page=1&filter[_and][0][_and][0][activities][item:attractionActivities][id][_eq]=${activityId}`
      )
      .then(function (res) {
        const {
          data: { data },
        } = res;
        // const filterData = data.filter(item => item.activities[0].attractionActivities_id.id == activityId)
        // const filterData = data.filter(item => item.activities.some(innerItem => innerItem.attractionActivities_id.id == activityId))
        setAttraction(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    attractionList();
  }, []);

  const breadCrumb = [
    {
      title: "Plan a Trip",
      pageLink: "/plan-a-visit",
    },
    {
      title: "Things to Do",
      pageLink: "/things-to-do",
    },
    {
      title: activityName,
      pageLink: "",
    },
  ];

  return (
    <div className="wrapper d-md-flex">
      {isLoading && <Loader />}
      <div className="offcanvas offcanvas-start" id="demo">
        <SideBar activeIndex="11" />
      </div>
      <SideBar activeIndex="11" />
      <HeaderMobile />
      <div className="dashboard-box mb-0">
        <InnerHeader
          title={activityName}
          breadCrumb={breadCrumb}
          previouPage="/things-to-do"
        />

        <div className="wrapper-container">
                <div className="w-100">                    
                    <div className="row text-decor-none"> 

                    {attraction.map((item, key)=>                       
                        <div className="col-xl-3 col-lg-4 col-sm-6" key={key}>
                             <Link to={`/activity-details/${item.id}?showActivity=true&activityId=${activityId}&activityName=${activityName}`} >
                                <div className="card-dark p-2">
                                    <div>
                                        <img src={`${assetesUrl}/${item.image}.svg`} className="rounded-14 object-fit-cover w-100 h-125" alt="Offbeat itineraries"/>
                                    </div>
                                    <div className="p-2">
                                        <h3 className="text-white h4-sm text-truncate">{item.name}</h3>
                                        {/* <div className="d-flex align-items-center">                                            
                                            <img src="/assets/images/icons/location.svg" className="" alt="Location"/>                                            
                                            <p className="mb-0 text-grey ps-1 text-truncate">{item.location}</p>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>                            
                        </div>
                        )}
                            
                    </div>            
                </div>                                    
            </div>
        <Footer />
      </div>
      <MoveTop />
    </div>
  );
};

export default ActivityAttractions;
