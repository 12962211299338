const Icon = ({fillColor}) => {
  return (
    <>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3335 10.0834V11.9167H15.5002L12.7502 2.75002V0.916687H10.9168V2.75002H9.056V0.916687H7.22266V2.86002L4.50016 11.9167H2.66683V10.0834H0.833496V20.1667H9.0835V15.5834H10.9168V20.1667H19.1668V10.0834H17.3335ZM13.0343 10.0834H6.966L7.516 8.25002H12.4843L13.0343 10.0834ZM11.9343 6.41669H8.066L8.616 4.58335H11.3843L11.9343 6.41669ZM17.3335 18.3334H12.7502V13.75H7.25016V18.3334H2.66683V13.75H5.866L6.416 11.9167H13.5843L14.1343 13.75H17.3335V18.3334Z"
          fill={fillColor}
        />
      </svg>
    </>
  );
};

export default Icon;
